import React, { ReactNode, useCallback, useState } from 'react';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { LightTooltip } from '..';
import { useSidebarOpenState } from '../sidebar/sidebar.slector';
import TranslateText from '../../../i18n/TranslateText';

interface FullScreenWrapperProps {
  children: ReactNode;
  /**
   * Custom Component that you might want to render besides the full screen button
   * like dropdown, etc.
   */
  CustomComponent?: JSX.Element;
  title?: string;
  /**
   * To disable the full screen buttons.
   */
  disableFullScreen?: boolean;
  /**
   * To check if the component is already in full screen mode.
   */
  isFullScreenFromUrl?: boolean;
  /**
   * Custom function to run when you click on the full screen button.
   */
  isOpen?: () => void;
  /**
   * Custom function to run when you click on the exit full screen button.
   */
  isClose?: () => void;
}

export const SIDEBAR_WIDTH = 255;
export const HEADER_HEIGHT = 44;

const FullScreenWrapper = ({
  children,
  title,
  CustomComponent,
  disableFullScreen,
  isFullScreenFromUrl,
  isOpen,
  isClose
}: FullScreenWrapperProps) => {
  const [isFullScreen, setIsFullScreen] = useState(isFullScreenFromUrl ? true : false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const openSidebar = useSidebarOpenState();

  const toggleFullScreen = useCallback(() => {
    if (disableFullScreen) {
      if (!isFullScreen && isOpen) {
        isOpen();
      } else if (isClose) {
        isClose();
      }
    } else {
      if (!isFullScreen && isOpen) {
        isOpen();
      } else if (isClose) {
        isClose();
      }
    }
    setIsFullScreen((prevState) => !prevState);
    setTooltipOpen(false); // Close the tooltip when toggling full screen
  }, [disableFullScreen, isClose, isFullScreen, isOpen]);

  return (
    <Box
      sx={{
        // Full screen widget custom styles.
        height: isFullScreen ? `calc(100vh - ${HEADER_HEIGHT}px)` : 'inherit',
        width: isFullScreen
          ? openSidebar
            ? `calc(100vw - ${SIDEBAR_WIDTH}px)`
            : `calc(100vw - 40px)`
          : 'inherit',
        position: isFullScreen ? 'fixed' : 'relative',
        top: isFullScreen ? HEADER_HEIGHT : 'auto',
        right: isFullScreen ? 0 : 'auto',
        zIndex: isFullScreen ? 10 : 'auto',
        backgroundColor: isFullScreen ? 'white' : 'transparent',
        padding: isFullScreen ? 2 : 0
      }}>
      <Grid
        container
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={{ backgroundColor: 'white' }}>
        <Grid item>
          <Typography variant="h6">{title}</Typography>
        </Grid>
        <Grid item>
          <Box display="flex" textAlign={'right'}>
            {CustomComponent && <Box mr={1}>{CustomComponent}</Box>}
            {!disableFullScreen && (
              <LightTooltip
                open={tooltipOpen}
                title={
                  isFullScreen
                    ? TranslateText('exit_full_screen', 'Exit Full Screen')
                    : TranslateText('view_full_screen', 'View Full Screen')
                }
                placement="bottom-start"
                onOpen={() => setTooltipOpen(true)}
                onClose={() => setTooltipOpen(false)}>
                <IconButton
                  onClick={toggleFullScreen}
                  onMouseEnter={() => setTooltipOpen(true)}
                  onMouseLeave={() => setTooltipOpen(false)}>
                  {isFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                </IconButton>
              </LightTooltip>
            )}
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ backgroundColor: 'white', height: 'inherit' }}>{children}</Box>
    </Box>
  );
};

export default FullScreenWrapper;
