import { skipToken } from '@reduxjs/toolkit/query';
import { useFetchTourConfigQuery, useUpdateTourInfoMutation } from './tour.api';
import { FetchTourArgs } from './tour.types';
import { UseQueryOptions } from '../../../framework/lib/rtk/RTK.types';
import { isValidSlug } from '../../../framework';

export const useFetchTourConfig = (
  args: FetchTourArgs | typeof skipToken,
  options?: UseQueryOptions
) => {
  const { data: tourConfig, isFetching: isFetchTourConfig } = useFetchTourConfigQuery(args, {
    skip: (args !== skipToken && 'slug' in args && !isValidSlug(args.slug)) || options?.skip
  });

  return {
    tourConfig,
    isFetchingTourConfig: isFetchTourConfig
  };
};
export const useUpdateTourInfo = () => {
  const [updateTourInfo, { isLoading: isUpdateTourLoading }] = useUpdateTourInfoMutation();

  return { updateTourInfo, isUpdateTourLoading };
};
