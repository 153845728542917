export enum DOWNSAMPLEMETHOD {
  DIFFERENCE = 'difference'
}

interface DownSamplingOptions {
  multiplier?: number;
}

export const MULTIPLIER = 0.08;

/**
 * Downsamples the given dataset based on the specified method and options.
 * @param rawData - The raw dataset to be downsampled, consisting of [x, y] points.
 * @param method - The downsampling method to be used.
 * @param options - Optional parameters for downsampling, including a multiplier.
 * @returns The downsampled dataset.
 */
export function downSampleData(
  rawData: [number, number][],
  method: DOWNSAMPLEMETHOD,
  options?: DownSamplingOptions
): [number, number][] {
  const dataset = rawData.sort((a, b) => a[0] - b[0]);
  const yValues = dataset?.map((point) => point[1]);
  const standardDeviation = calculateStandardDeviation(yValues);
  const multiplier = options?.multiplier || MULTIPLIER;
  const threshold = standardDeviation * multiplier;

  switch (method) {
    case DOWNSAMPLEMETHOD.DIFFERENCE:
      return downsampleDataBasedOnDifference(dataset, threshold);
    default:
      return rawData;
  }
}

/**
 * Downsamples the given data based on the specified difference threshold.
 *
 * This function reduces the number of data points in the original dataset by
 * including only those points that have a significant difference from their
 * neighboring points or from a reference value. The first and last points are
 * always included in the downsampled data.
 *
 * @param originalData - The original dataset represented as an array of tuples,
 * where each tuple contains a timestamp (number) and a value (number).
 * @param differenceThreshold - The threshold value used to determine whether
 * a data point should be included in the downsampled data. If the difference
 * between a data point and its neighbors or the reference value is greater
 * than or equal to this threshold, the point is included.
 * @returns The downsampled dataset as an array of tuples.
 */
function downsampleDataBasedOnDifference(
  originalData: [number, number][],
  differenceThreshold: number
): [number, number][] {
  const downsampledData: [number, number][] = [];
  if (originalData.length < 3) return originalData;

  downsampledData.push(originalData[0]);
  let referenceValue = originalData[0][1];

  for (let i = 1; i < originalData.length - 1; i++) {
    const previousValue = originalData[i - 1][1];
    const currentValue = originalData[i][1];
    const nextValue = originalData[i + 1][1];
    const differenceFromReference = Math.abs(currentValue - referenceValue);

    const differenceFromPrevious = Math.abs(currentValue - previousValue);
    const differenceFromNext = Math.abs(currentValue - nextValue);

    if (
      differenceFromPrevious >= differenceThreshold ||
      differenceFromNext >= differenceThreshold
    ) {
      downsampledData.push(originalData[i]);
      referenceValue = currentValue;
    } else if (differenceFromReference >= differenceThreshold) {
      downsampledData.push(originalData[i]);
      referenceValue = currentValue;
    }
  }

  downsampledData.push(originalData[originalData.length - 1]);
  return downsampledData;
}

function calculateStandardDeviation(dataset: number[]): number {
  const mean = dataset.reduce((acc, val) => acc + val, 0) / dataset.length;
  const variance = dataset.reduce((acc, val) => acc + Math.pow(val - mean, 2), 0) / dataset.length;
  const stdDev = Math.sqrt(variance);
  return stdDev;
}
