/**
 * Login Saga
 * @author mahesh.kedari@shorelineiot.com
 */
import { takeEvery, all } from 'redux-saga/effects';
import * as ACTIONS from '../actions/login.actionTypes';
import * as RBAC_ACTION from '../actions/rbac.actionTypes';

import { logoutSaga } from './logout.saga';
import { authUserSaga } from './authUser.saga';
import { resendVerificationLink } from './resendVerification.saga';
import { watchForgotPasswordSagas } from './forgotPassword.saga';
import { getPermissions } from './rbac.saga';
import { authCheckStatus } from './authCheckStatus.saga';

/**
 *
 */
export function* loginSagas() {
  yield all([
    takeEvery(ACTIONS.LOGIN_PROGERSS, authUserSaga),
    takeEvery(ACTIONS.LOGOUT_PROGRESS, logoutSaga),
    takeEvery(ACTIONS.RESEND_VERIFICATION, resendVerificationLink),
    watchForgotPasswordSagas(),
    takeEvery(RBAC_ACTION.RBAC_PERMISSION_PROGRESS, getPermissions),
    takeEvery(ACTIONS.CHECK_CURRENT_SESSION, authCheckStatus)
  ]);
}
