/**
 *
 */

import { BreadcrumbType } from './breadcrumbTypes';

export type BreadcrumbState = {
  breadcrumbs: Array<BreadcrumbType>;
  /**
   * This propery has been added in case we need to show a custom icon action before the breadcrumb.
   * If set to `true`, it will move the breadcrumb to the `80px` right, leaving some space for the icon.
   * Then we need to move our required icon to upside by appliying negative marginTop.
   */
  shouldMoveBreadcrumbToRight: {
    shouldMoveToRight?: boolean;
    pixel?: number;
  };
};

export enum BREADCRUMB_ACTION {
  ADD = 'add',
  REMOVE = 'remove'
}
