/**
 *
 */
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  collapsedBreadcrumbs: {
    zIndex: 1,
    width: '70%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '& .MuiBreadcrumbs-ol': {
      flexWrap: 'nowrap !important'
    },
    '& .MuiBreadcrumbs-li': {
      '@media (min-width: 1300px)': {
        width: '4%'
      },
      '@media (min-width: 1400px)': {
        width: '7%'
      },
      '@media (min-width: 1600px)': {
        width: '10%'
      },
      maxWidth: 'min-content',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      transition: 'all 1s ease'
    },
    '& .MuiBreadcrumbs-li:hover': {
      width: '100% !important',
      overflow: 'inherit',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    },
    '& .MuiBreadcrumbs-li:first-child': {
      '@media (min-width: 1300px)': {
        width: '5%'
      },
      '@media (min-width: 1400px)': {
        width: '6%'
      },
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    },
    '& .MuiBreadcrumbs-li:last-child': {
      width: 'auto',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }
  },
  breadcrumbs: {
    zIndex: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '& .MuiBreadcrumbs-ol': {
      flexWrap: 'nowrap !important'
    }
  },
  breadcrumbsMoveToRight: {
    marginLeft: ({ buttonWidth }: { buttonWidth: number | undefined }) =>
      `${buttonWidth ? buttonWidth : 0}px`
  }
}));
