import { Box, Grid, Typography } from '@mui/material';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useState, useEffect, useMemo } from 'react';
import WidgetDataUnavailable from '../widget-data-unavailable/WidgetDataUnavailable';
import { useStyles } from './gaugeWidget.styles';
import { gaugeWidgetSettingsGenerator } from './gaugeWidgetSettingsGenerator';
import TranslateText from '../../../../i18n/TranslateText';
import { setDatapointValueResolution } from '../SLLineChart/utils/setDatapointValueResolution';

interface GaugeData {
  min: number;
  max: number;
  /**
   * Current value
   */
  avg: number;
  /**
   * Range contains the ```start``` & ```end``` value to
   * be plotted in the chart.
   */
  range: any;
  units: string;
  message?: string;
}

interface GaugeProps {
  data: GaugeData;
  widgetSettings: any;
}

const SLGaugeWidget = React.forwardRef(({ data, widgetSettings }: GaugeProps, ref: any) => {
  const classes = useStyles();

  const avgFormattedValue = Number(setDatapointValueResolution(undefined, data?.avg, data?.units));
  const minFormattedValue = Number(setDatapointValueResolution(undefined, data?.min, data?.units));
  const maxFormattedValue = Number(setDatapointValueResolution(undefined, data?.max, data?.units));

  const rangeFormattedValue = useMemo(() => {
    return {
      start: Number(setDatapointValueResolution(undefined, data?.range?.start, data?.units)),
      end: Number(setDatapointValueResolution(undefined, data?.range?.end, data?.units))
    };
  }, [data?.range?.end, data?.range?.start, data?.units]);

  const [gaugeSettings, setGaugeSettings] = useState(
    gaugeWidgetSettingsGenerator(
      avgFormattedValue,
      rangeFormattedValue,
      data?.units,
      widgetSettings,
      ref?.current?.container?.current?.parentNode?.offsetParent?.clientHeight
    )
  );

  useEffect(() => {
    setGaugeSettings(
      gaugeWidgetSettingsGenerator(
        avgFormattedValue,
        rangeFormattedValue,
        data?.units,
        widgetSettings,
        ref?.current?.container?.current?.parentNode?.offsetParent?.clientHeight
      )
    );
  }, [
    avgFormattedValue,
    data.avg,
    data.range,
    data?.units,
    rangeFormattedValue,
    ref,
    widgetSettings
  ]);

  if (!data || (data?.message && data?.message === 'No data')) {
    return <WidgetDataUnavailable />;
  }

  return (
    <Box>
      <Box>
        <HighchartsReact highcharts={Highcharts} options={gaugeSettings} ref={ref} />
      </Box>
      <Box>
        <Grid container xs={12} justifyContent="space-between" alignItems="center">
          <Grid item className={classes.minMax}>
            <Typography variant="subtitle1">
              {TranslateText('min', 'Min')}: {minFormattedValue}
            </Typography>
          </Grid>
          <Grid item className={classes.minMax}>
            <Typography variant="subtitle1">
              {TranslateText('max', 'Max')}: {maxFormattedValue}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
});

export default SLGaugeWidget;
