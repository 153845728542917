/**
 * Forgot Password Page
 * @author mahesh.kedari@shorleineiot.com
 */
import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { LoginStateType, ACCEPT_INVITE_STATUS, useLoginState } from '../store';
import {
  useSlugContext,
  showProgressFor,
  useQuery,
  SLLoadingPage,
  showToast
} from '../../../../framework';
import { ResetPasswordForm } from './ResetPasswordForm';
import InviteAcceptFailed from './InviteAcceptFailed';
import { InvitationExpired } from './InvitationExpired';
import { acceptInviteActions, loginActions } from '../store/actions';
import { AuthProviderActions, ProviderState, PROVIDER_STATUS, useProviderState } from '../../store';

const LOGIN_ACTION_PROGRESS_ID = 'LOGIN_ACTION_PROGRESS_ID';
const AcceptInvite: React.FC = () => {
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const query = useQuery();
  const email = query.get('email')?.toLowerCase();
  const domain = email?.split('@')?.[1];
  const password = query.get('password');
  const navigate = useNavigate();
  // const url: string = window.location.href;
  const { slug } = useSlugContext();
  const loginState: LoginStateType = useLoginState();
  const providerState: ProviderState = useProviderState();

  useEffect(() => {
    if (providerState.status === PROVIDER_STATUS.INIT && domain) {
      dispatch(AuthProviderActions.getProviderForDomain(domain));
    }
  }, []);

  useEffect(() => {
    if (providerState.status !== PROVIDER_STATUS.READY) {
      return;
    }
    const role = query.get('role');
    const subOrgSlugs = query.get('slugs')?.split(',');
    if (providerState.providerType === 'federated') {
      // Handle Federated invite accept flow here
      dispatch(
        acceptInviteActions.markAcceptInviteInProgress(
          email,
          slug,
          subOrgSlugs,
          role,
          providerState.providerName
        )
      );
    } else if (loginState.acceptInviteStatus === ACCEPT_INVITE_STATUS.ALREADY_ACCEPTED) {
      // Invitation accepted Successfully
      dispatch(acceptInviteActions.resetAcceptInvite());
      navigate(`${slug}/app`);
    } else if (!password && loginState.status !== 'LOGGED_IN' && email) {
      // no password, it indicates that this is an existing user
      localStorage.setItem('inviteSlugs', query.get('slugs') as string);
      dispatch(showToast('Please login to accept the invitation', 'warning'));
      const redirectionPath = encodeURIComponent(`${pathname}${search}`);
      dispatch(
        loginActions.setUserEmail({ email, subOrgSlugs, role: role || '', acceptInvite: true })
      );
      navigate(`/${slug}/auth/login?redirect=${redirectionPath}`);
    } else if (
      (loginState.status === 'LOGGED_OUT' || loginState.status === 'NOT_LOGGED_IN') &&
      password &&
      loginState.acceptInviteStatus === ACCEPT_INVITE_STATUS.INIT
    ) {
      // Validate Password
      dispatch(acceptInviteActions.validatePassword({ password, slug }));
    } else if (
      (loginState.status === 'LOGGED_OUT' || loginState.status === 'NOT_LOGGED_IN') &&
      password &&
      loginState.acceptInviteStatus === ACCEPT_INVITE_STATUS.PASSWORD_VALIDATED
    ) {
      // After existing user has been logged out or not yet logged in, then login newly
      // invited user with credentials provided in the URL.
      // *Note* - Before dispatching 'acceptInvite' action, there must
      // be a already logged in user present in the system. That's why
      // new user being logged in immidietly.
      dispatch(showProgressFor(LOGIN_ACTION_PROGRESS_ID));
      const data = {
        email,
        password,
        subOrgSlugs,
        rememberme: false
      };
      // Login new user coming from accept invite link.
      dispatch(loginActions.login(data));
    } else if (
      loginState.status === 'LOGGED_IN' &&
      loginState.acceptInviteStatus === ACCEPT_INVITE_STATUS.INIT &&
      email?.toLowerCase() === loginState?.session?.email?.toLowerCase()
    ) {
      // After new user has been logged in, then
      // dispatch 'acceptInvite' action.
      const data = {
        slug,
        subOrgSlugs,
        role
      };
      dispatch(acceptInviteActions.acceptInvite(data));
    } else if (
      loginState.status === 'LOGGED_IN' &&
      loginState.acceptInviteStatus === ACCEPT_INVITE_STATUS.SUCCESS
    ) {
      navigate(`/${slug}/app`);
    }
  }, [loginState.status, loginState.acceptInviteStatus, providerState.status]);

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      {loginState.status === 'RESET_PASSWORD_REQUIRED' && <ResetPasswordForm />}
      {loginState.status === 'ERROR' ||
        (loginState.acceptInviteStatus === ACCEPT_INVITE_STATUS.ERROR && <InviteAcceptFailed />)}
      {loginState.acceptInviteStatus === ACCEPT_INVITE_STATUS.EXPIRED && <InvitationExpired />}
      {loginState.status === 'ERROR' && <InviteAcceptFailed />}
      {loginState.status === 'CHECKING_CURRENT_SESSION' ||
      loginState.status === 'LOGIN_IN_PROGRESS' ||
      loginState.acceptInviteStatus === ACCEPT_INVITE_STATUS.ACCEPTING ||
      loginState.acceptInviteStatus === ACCEPT_INVITE_STATUS.PASSWORD_VALIDATING ? (
        <SLLoadingPage />
      ) : null}
    </Box>
  );
};

export default AcceptInvite;
