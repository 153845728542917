/**
 * Framwork's top level container.
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { ReactElement } from 'react';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter } from 'react-router-dom';
import { SLProgressBar } from '../progress-bar';
import { SLToast, SLToastTemplate } from '../toast';
import SLBackdrop from '../backdrop/Backdrop';

/**
 *
 */
interface Props {
  maxSnack?: number;
  children: ReactElement;
}
/**
 *
 */
export default function FrameworkContainer({ maxSnack = 4, children }: Props): ReactElement {
  const SnackbarContent = React.forwardRef<
    HTMLDivElement,
    { message: string; key: string; [key: string]: string }
  >((props, ref) => {
    const { message, key, ...other }: { message: string; key: string; [key: string]: string } =
      props;
    return (
      <div ref={ref}>
        <SLToastTemplate id={key} message={message} {...other} />
      </div>
    );
  });
  return (
    <>
      <SLBackdrop />
      <BrowserRouter>
        <SLProgressBar />
        <SnackbarProvider
          maxSnack={maxSnack}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          hideIconVariant
          Components={{
            default: SnackbarContent,
            success: SnackbarContent,
            error: SnackbarContent,
            warning: SnackbarContent,
            info: SnackbarContent
          }}>
          <>
            <SLToast />
            {children}
          </>
        </SnackbarProvider>
      </BrowserRouter>
    </>
  );
}
