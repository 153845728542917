/**
 * Validates whether a given slug is valid based on the following criteria:
 * - The slug must not be null, undefined, or an empty string.
 * - The slug must not contain only whitespace characters.
 * - The slug must not be equal to the string "org" (case-sensitive).
 *
 * @param slug - The slug to validate (optional).
 * @returns `true` if the slug is valid, otherwise `false`.
 *
 * @example
 * ```typescript
 * isValidSlug("my-slug"); // Returns true
 * isValidSlug(" org "); // Returns false (invalid slug)
 * isValidSlug(""); // Returns false (empty string)
 * isValidSlug(undefined); // Returns false (undefined input)
 * ```
 */
export const isValidSlug = (slug?: string): boolean => {
  if (!slug) {
    return false; // Handle null, undefined, or empty string directly
  }

  const sanitizedSlug = slug.trim(); // Remove unnecessary whitespace
  return sanitizedSlug.length > 0 && sanitizedSlug !== 'org'; // Validate against the 'org' condition
};

export default isValidSlug;
