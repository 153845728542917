/**
 * Truncates a number to the specified number of decimal places without rounding.
 * Ensures that the result always has `decimals` number of digits after the decimal point,
 * adding trailing zeros if necessary.
 *
 * @param num - The number to truncate.
 * @param decimals - The number of decimal places to keep.
 * @returns The truncated number as a string (preserving trailing zeros).
 */
const truncateDecimals = (num: number, decimals: number): string => {
  // Convert number to string to manipulate decimal places
  const [integerPart, decimalPart = ''] = num.toString().split('.'); // Ensure decimalPart exists

  // Truncate or pad the decimal part to match the required number of decimal places
  const truncatedDecimal = decimalPart.slice(0, decimals).padEnd(decimals, '0');

  // Return formatted number as a string to preserve trailing zeros
  return `${integerPart}.${truncatedDecimal}`;
};

/**
 * Formats a sensor's datapoint value to the desired decimal resolution.
 *
 * If the value is a number, it truncates it to the specified resolution (i.e. decimal places).
 * For non-numerical values, the original value is returned.
 *
 * @param value - The sensor's datapoint value (can be a string or a number).
 * @param resolution - The desired number of decimal places for numerical values.
 * @returns The formatted datapoint value (as a number if input was a number, or the original string).
 */
function checkDatatypeAndSetResolution(value: string | number, resolution: number) {
  if (typeof value === 'number') {
    return truncateDecimals(value, resolution);
  }
  return value;
}

/**
 * Sets the datapoint value resolution based on the sensor type and unit.
 *
 * This function determines the appropriate decimal resolution for a sensor's datapoint
 * based on the provided sensor type or unit. It then formats the datapoint value accordingly.
 *
 * @param sensorType - The type of sensor.
 * @param datapointValue - The value of the sensor's datapoint.
 * @param unit - The unit associated with the datapoint.
 * @returns The datapoint value with the desired decimal resolution.
 */
export const setDatapointValueResolution = (
  sensorType?: string,
  datapointValue?: any,
  unit?: string
) => {
  if (unit) {
    if (unit === 'hz' || unit === 'percent' || unit === 'degree-F' || unit === 'degree-C') {
      return checkDatatypeAndSetResolution(datapointValue, 1);
    }
  }

  /* SensorType Criteria */
  switch (sensorType) {
    case 'sns_total_vibration_energy':
      return checkDatatypeAndSetResolution(datapointValue, 4);
    case 'sns_accelerometer':
      return checkDatatypeAndSetResolution(datapointValue, 4);
    case 'sns_temperature':
      return checkDatatypeAndSetResolution(datapointValue, 1);
    case 'sns_humidity':
      return checkDatatypeAndSetResolution(datapointValue, 1);
    case 'sns_battery':
      return checkDatatypeAndSetResolution(datapointValue, 4);
    case 'sns_narrowband':
      return checkDatatypeAndSetResolution(datapointValue, 3);
    case 'sns_band':
      return checkDatatypeAndSetResolution(datapointValue, 4);
    case 'sns_rsrp':
      return checkDatatypeAndSetResolution(datapointValue, 4);
    case 'sns_rsrq':
      return checkDatatypeAndSetResolution(datapointValue, 4);
    case 'sns_csq':
      return checkDatatypeAndSetResolution(datapointValue, 4);
    case 'sns_sinr':
      return checkDatatypeAndSetResolution(datapointValue, 4);
    case 'sns_ai':
      return checkDatatypeAndSetResolution(datapointValue, 4);
    case 'sns_mic_airborne':
      return checkDatatypeAndSetResolution(datapointValue, 4);
    case 'sns_mic_struct_borne':
      return checkDatatypeAndSetResolution(datapointValue, 4);
    case 'sns_barometer':
      return checkDatatypeAndSetResolution(datapointValue, 1);
    case 'analog_input':
      return checkDatatypeAndSetResolution(datapointValue, 4);
    case 'modbus':
      return checkDatatypeAndSetResolution(datapointValue, 1);
    default:
      return checkDatatypeAndSetResolution(datapointValue, 4);
  }
};
