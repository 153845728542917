/**
 * Forgot Password Saga
 * @author mahesh.kedari@shorelineiot.com
 */
import { put, takeEvery } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as actions from '../actions/login.actions';
import * as ACTION_TYPES from '../actions/login.actionTypes';
import { showToast, SafeSaga } from '../../../../../framework';
import { AuthResponse, AuthService } from '../../../../../framework/services/auth.service';
function* forgotPassword(action: AnyAction) {
  const amplifyService = new AuthService();
  const email = action.payload;
  const response: AuthResponse = yield amplifyService.forgotPassword(email);

  if (response.type === 'SUCCESS') {
    yield put(showToast('Reset password link will be sent to your email if it exists', 'success'));
    yield put(actions.forgotPasswordCodeSuccess(action.payload));
  } else {
    yield put(showToast(response.message || 'Error in sending reset link', 'error', true));
    yield put(actions.forgotPasswordCodeFailure(response.message));
  }
}

function* errorHandler(error: { message: string }) {
  yield put(actions.forgotPasswordCodeFailure(error.message));
}

export function* watchForgotPasswordSagas() {
  yield takeEvery(
    ACTION_TYPES.FORGOT_PASSWORD_CODE,
    SafeSaga(forgotPassword, ACTION_TYPES.FORGOT_PASSWORD_CODE, errorHandler)
  );
}
