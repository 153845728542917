import { MULTIPLIER } from './utils/downSamplingData.utils';

export const cartesianFormDefaults = {
  xAxisTitle: '',
  marker: false,
  'grid.disable': false,
  'grid.width': '',
  'crosshair.enable': false,
  'crosshair.color': '',
  'options3d.enabled': false,
  'options3d.alpha': 15,
  'options3d.beta': 15,
  'options3d.depth': 50,
  threshold: MULTIPLIER
};

export const donutFormDefaults = {
  title: '',
  innerSize: '50',
  legands: true,
  enable3d: false,
  dataLabelType: 'PERCENTAGE'
};

export const pieFormDefaults = {
  title: '',
  innerSize: '',
  legands: true,
  enable3d: false,
  dataLabelType: 'PERCENTAGE'
};

export const pieChartUnwantedKeysObject = {
  title: '',
  innerSize: '',
  legands: true,
  enable3d: false,
  dataLabelType: 'PERCENTAGE',
  chartType: 'donut',
  data_label_value_unit: 'ABSOLUTE',
  donutTitle: 'Assets',
  size: '',
  dataSettings: {}
};

export const donutChartUnwantedKeysObject = {
  title: '',
  innerSize: '50',
  legands: true,
  enable3d: false,
  dataLabelType: 'PERCENTAGE',
  chartType: 'donut',
  data_label_value_unit: 'ABSOLUTE',
  donutTitle: 'Assets',
  size: '50',
  dataSettings: {}
};
