/**
 * Asset Severity Age Viewer
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { useStyles } from './tableWidget.styles';
import { ChartViewProps } from '../../common/ChartViewProps';
import {
  ORG_LIST_STATUS,
  useOrgSelector,
  useSubOrgList
} from '../../../../../features/organization/store';
import ConditionalFormattedColumn from '../../table/viewer/ConditionalFormattedColumn';
import SLTable from '../../../table/SLTable';
import { SLTableColumn } from '../../../table/slTable.types';

const columns = [
  {
    key: 'suborg_uuid',
    title: 'Site',
    width: 150,
    cellStyle: {}
  },
  {
    key: 'powertrain_name',
    title: 'Asset',
    width: 150,
    cellStyle: {}
  },
  {
    key: 'alarm_age',
    title: 'Age (Days)',
    width: 150,
    cellStyle: {}
  },
  { key: 'severity', title: 'Severity', width: 80, cellStyle: {} }
];
/**
 *
 * @param param0
 * @returns
 */
const SLTableView: React.FC<ChartViewProps> = React.forwardRef(
  ({ resultSet, chartSettings }: ChartViewProps, ref: any) => {
    const classes = useStyles();
    const location = useLocation();

    const stageCanvasRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
    const [tableHeight, setTableHeight] = useState(400);
    const orgSelector = useOrgSelector();

    const subOrgListState = useSubOrgList();

    const pathName = location?.pathname?.split('/');
    const isInEditWidgetMode = pathName[5] === 'edit' || false;

    useImperativeHandle(
      ref,
      () => ({
        resize() {
          const height: number = stageCanvasRef?.current?.offsetParent?.clientHeight ?? 500;
          setTableHeight(height);
        }
      }),
      []
    );

    useEffect(() => {
      if (stageCanvasRef?.current?.offsetParent?.clientHeight && !isInEditWidgetMode) {
        const height = stageCanvasRef?.current?.offsetParent?.clientHeight;
        if (height >= stageCanvasRef?.current?.scrollHeight) {
          setTableHeight(height);
        }
      } else {
        setTableHeight(500);
      }
    }, [stageCanvasRef?.current]);

    // **Code-Snippet-1 START**
    // Sub-Org Name Logic for specific specific suborg-uuid

    const getSubOrgInfo = (subOrgUUID: string) => {
      if (subOrgListState.status === ORG_LIST_STATUS.READY && subOrgListState.orgList.length > 0) {
        const subOrgInfo = subOrgListState?.orgList.find((subOrg) => subOrg.uuid === subOrgUUID);
        return subOrgInfo?.name;
      }
      return '';
    };

    const checkAndGetSubOrgName = (subOrgUUID: string) => {
      const subOrgName = getSubOrgInfo(subOrgUUID);
      if (subOrgName && subOrgName === '_phantom') {
        return orgSelector?.selectedOrg?.name;
      }
      return subOrgName || orgSelector?.selectedOrg?.name;
    };

    // **Code-Snippet-1 END**

    const getColumnTitle = (column: any) => {
      const configKey = column.key.replaceAll('.', '_');
      const columnSettings = chartSettings?.dataSettings?.[configKey];
      if (columnSettings && columnSettings.title) {
        return columnSettings.title;
      }
      return column.title;
    };

    /**
     * This function will determine if a specific column should be hidden or not.
     * @param0 chartData This will be a chartSettings object where we have dataSettings key.
     * @param1 columnData This is individual column data for the table.
     * @returns true/false
     */
    const isColumnHidden = (chartData: any, columnData: any) => {
      const formattedKey = columnData?.key?.replaceAll('.', '_');
      const hiddenColumn = chartData?.dataSettings?.[formattedKey]?.hideColumn === true;
      if (hiddenColumn) {
        return true;
      }
      return false;
    };
    return (
      <>
        <div className={classes.tableRoot} ref={stageCanvasRef}>
          <SLTable
            feature="Asset Severity Age"
            data={resultSet || []}
            csvOptions={{ filename: 'Asset Severity Age' }}
            columns={
              columns
                .filter((column) => !isColumnHidden(chartSettings, column))
                .map((column) => ({
                  accessorKey: column.key,
                  header: column.title,
                  Header: <div className={classes.colHeader}>{getColumnTitle(column)}</div>,
                  enableSorting: false,
                  CellRenderer: ({ cellValue, rowObj }) => {
                    const configKey = column.key;
                    const columnSettings = chartSettings?.dataSettings?.[configKey];
                    const columnData =
                      configKey == 'suborg_uuid' ? checkAndGetSubOrgName(cellValue) : cellValue;
                    return (
                      <ConditionalFormattedColumn
                        data={columnData}
                        conditions={columnSettings?.conditions}
                        redirection={columnSettings?.redirection}
                        params={columnSettings?.params}
                        rowData={rowObj}
                        chartSettings={chartSettings}
                      />
                    );
                  },
                  size: column.width,
                  minSize: column.width
                })) as SLTableColumn<Record<string, string | number>>[]
            }
            enableColumnResizing
            maxBodyHeight={tableHeight - 175}
            fixedLayout
            enableSorting
            enableBottomToolbar
            enablePagination
          />
        </div>
      </>
    );
  }
);

export default SLTableView;
