import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, TextField, FormHelperText, IconButton, Typography, Theme } from '@mui/material';
import { useGAEventTracker } from '../../../custom-hooks';
import { RebootIcon } from '../../../icons';
import { LightTooltip } from '../../tooltip/Tooltip';
import { ZoomConfig } from './SLWaterfallChartEditor';
import TranslateText from '../../../../i18n/TranslateText';

type Props = {
  setXAxisZoomConfig: React.Dispatch<React.SetStateAction<ZoomConfig>>;
  xAxisZoomConfig: ZoomConfig;
};

const useStyles = makeStyles((theme: Theme) => ({
  inputBox: {
    width: '80px',
    marginBottom: '15px',
    marginRight: '15px'
  },
  zoom: {
    fontWeight: theme.typography.fontWeightBold
  }
}));

export default function SLChartXAxisZoomHandler({ setXAxisZoomConfig, xAxisZoomConfig }: Props) {
  const classes = useStyles();
  const GAEventsTracker = useGAEventTracker('Waterfall min max');
  const [showError, setShowError] = useState(false);

  function setChartZoomHandler(newZoomValues: any) {
    const newValues = {
      min: newZoomValues.min === null ? 0 : newZoomValues.min,
      max: newZoomValues.max
    };

    if (newValues.min < newValues.max || newValues.max === null) {
      setShowError(false);
    } else {
      setShowError(true);
    }
    setXAxisZoomConfig(newValues);
  }

  const handleMinMaxZoomChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: 'min' | 'max'
  ) => {
    const value = Number(event?.target?.value || null);
    const newZoomValues = {
      min: xAxisZoomConfig?.min,
      max: xAxisZoomConfig?.max
    };

    const regex = /^\d*\.?\d{0,4}$/;
    if (regex.test(String(value))) {
      newZoomValues[type] = value;
      setChartZoomHandler(newZoomValues);
    }

    GAEventsTracker(
      'WaterFall min max change',
      ` min: ${newZoomValues.min},
      max: ${newZoomValues.max}`
    );
  };

  const resetDefault = () => {
    const newZoomValues = { min: 0, max: null };
    setChartZoomHandler(newZoomValues);
  };

  return (
    <>
      <Grid container alignItems="center" justifyContent="space-between" columnSpacing={3}>
        <Grid item xs={2.5}>
          <Typography variant="body1" className={classes.zoom}>
            {TranslateText('zoom_x', 'Zoom X')}{' '}
          </Typography>
        </Grid>
        <Grid container item alignItems="center" xs={9.5}>
          <Grid item xs={1.5}>
            <Typography variant="body1">{TranslateText('min', 'Min')}:</Typography>
          </Grid>
          <Grid item xs={3.5}>
            <TextField
              className={classes.inputBox}
              type="number"
              value={xAxisZoomConfig?.min}
              onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                handleMinMaxZoomChange(event, 'min')
              }
            />
          </Grid>
          <Grid item xs={1.5}>
            <Typography variant="body1">{TranslateText('max', 'Max')}:</Typography>
          </Grid>
          <Grid item xs={3.5}>
            <TextField
              className={classes.inputBox}
              type="number"
              value={xAxisZoomConfig?.max !== null ? xAxisZoomConfig?.max : ''}
              onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                handleMinMaxZoomChange(event, 'max')
              }
            />
          </Grid>
          <Grid item xs={2}>
            <LightTooltip title="Reset X Zoom" translationId="reset_x_zoom">
              <IconButton size="small" onClick={resetDefault}>
                <RebootIcon className="MuiSvgIcon-root" />
              </IconButton>
            </LightTooltip>
          </Grid>
        </Grid>
      </Grid>
      {showError && <FormHelperText error>Invalid chart range</FormHelperText>}
    </>
  );
}
