/**
 * Function to generate gauge settings for Highchart.
 * @author aditya.bhadange@shorelineiot.com
 */

/**
 * Function to generate gauge settings for Highchart.
 * @param avgValue
 * @param range ```{start: number, end: number}```
 * @param unit Unit that needs to be shown. ```string```
 * @param widgetSettings
 * @param containerHeight Parent height of the card container. ```number```
 * @returns Highchart configuration object for the gauge widget.
 */
export const gaugeWidgetSettingsGenerator = (
  avgValue: number,
  range: {
    start: number;
    end: number;
  },
  unit: string,
  widgetSettings: any,
  containerHeight?: number | undefined
) => {
  const gaugeSettings: Highcharts.Options = {
    chart: {
      type: 'gauge',
      plotBackgroundColor: undefined,
      plotBackgroundImage: undefined,
      plotBorderWidth: 0,
      plotShadow: false,
      height: containerHeight ? containerHeight - 70 : null,
      margin: [-45, 0, 0, 0]
    },

    credits: {
      enabled: false
    },

    title: {
      text: undefined
    },

    pane: {
      startAngle: -150,
      endAngle: 150,
      size: '50%',
      background: [
        {
          backgroundColor: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            stops: [
              [0, '#848484'],
              [1, '#E5E5E5']
            ]
          },
          borderWidth: 0,
          outerRadius: '150%',
          innerRadius: '145%'
        },
        {
          backgroundColor: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            stops: [
              [0, '#FFF'],
              [1, '#FFF']
            ]
          },
          borderWidth: 0
        },
        {
          backgroundColor: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            stops: [
              [0, '#E0E0DF'],
              [0.7, '#E0E0DF'],
              [0.8, '#E0E0DF'],
              [0.85, '#E0E0DF'],
              [0.89, '#E0E0DF'],
              [0.9, '#E0E0DF'],
              [0.91, '#E0E0DF'],
              [0.92, '#FFFF'],
              [0.93, '#FFF'],
              [1, '#FFF']
            ]
          },
          borderWidth: 0,
          innerRadius: '98%'
        }
      ]
    },

    // the value axis
    yAxis: {
      min: range?.start,
      max: range?.end,

      minorTickInterval: 'auto',
      minorTickWidth: 1,
      minorTickLength: 10,
      minorTickPosition: 'outside',
      minorTickColor: '#666',
      minorTicks: false,

      tickPixelInterval: 40,
      tickWidth: 4,
      tickPosition: 'outside',
      tickLength: 10,
      tickColor: '#666',
      labels: {
        step: 1,
        distance: 20
      },
      title: {
        text: unit
      },
      plotBands: widgetSettings?.plotBands
    },

    plotOptions: {
      gauge: {
        clip: true,
        dial: {
          radius: '102%',
          baseWidth: 12,
          borderWidth: 0,
          topWidth: 0,
          rearLength: '0%',
          baseLength: '5%', // of radius
          backgroundColor: '#EE5927'
        },
        pivot: {
          radius: 20,
          backgroundColor: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            stops: [
              [0, '#675F5F'],
              [0.5, '#C6C5C5'],
              [1, '#675F5F']
            ]
          }
        }
      }
    },

    series: [
      {
        data: [avgValue],
        type: 'gauge',
        name: unit,
        dataLabels: {
          y: 50,
          style: {
            fontSize: '110%'
          }
        },
        tooltip: {
          valueSuffix: undefined
        }
      }
    ]
  };

  return gaugeSettings;
};
