import { generateTags } from '../../../framework/lib/rtk/RTK.utils';
import { assetWidgetDataMessageHandler } from '../../common/websocket/device/assetWidgetDataMessageHandler';
import {
  deviceMessageHandler,
  groupsMessageHandler,
  standaloneDevicesMessageHandler
} from '../../common/websocket/device/deviceMessageHandler';
import { featureApi } from '../../feature.api';
import {
  createDeviceQuery,
  createGroupQuery,
  createPowertrainQuery,
  deleteDevicePhotoQuery,
  deleteGroupQuery,
  deletePowertrainQuery,
  editGroupQuery,
  fetchAssetWidgetDataQuery,
  fetchDefaultScheduleConfigQuery,
  fetchDeviceDatapointsQuery,
  fetchDeviceDeviceSensorTemplatesQuery,
  fetchDeviceEventsTrendQuery,
  fetchDeviceGroupsQuery,
  fetchDeviceProfilesDetailsByCompatibleDeviceTypeQuery,
  fetchDeviceProfilesDetailsByDeviceProfileIdQuery,
  fetchDeviceProfilesQuery,
  fetchDeviceQuery,
  fetchDeviceWidgetSetting,
  fetchDevicesByFirmwareIdQuery,
  fetchDevicesQuery,
  fetchDevicesTypesQuery,
  fetchGenericDashboardQuery,
  fetchHistoricalDataWithoutSamplingQuery,
  fetchImageWidgetDataQuery,
  fetchMultipleDevicePhotosQuery,
  fetchOrbitPlotDataQuery,
  fetchPowertrainComponentsQuery,
  fetchPowertrainDetailQuery,
  fetchSensorWidgetDataQuery,
  fetchStandaloneDevicesQuery,
  moveDeviceToGroupQuery,
  moveGroupToGroupQuery,
  updateDeviceProfileQuery,
  updatePowertrainQuery,
  updateSensorWidgetDataQuery,
  replaceDevicePhotoQuery,
  uploadDevicePhotoQuery,
  deleteDeviceQuery,
  updateDeviceSimSettingsQuery,
  updateDeviceSettingsQuery,
  updateDeviceFirmwareQuery,
  rebootDeviceQuery,
  fetchCategoriesQuery,
  fetchAdditionalSensorsQuery,
  createDeviceWidgetsSetting,
  updateDeviceWidgetSetting,
  fetchRawDatapointsByDeviceIdsQuery,
  fetchHistoricalTrendDataQuery,
  fetchMultipleDeviceDatapointsQuery,
  fetchBatteryStatusQuery,
  fetchAnomalyListQuery,
  fetchLatestPowetrainConfigQuery,
  fetchDeviceMailboxQuery,
  fetchAlertsQuery
} from './device-utils/deviceQuery.utils';
import { setupWebSocketListener } from './device-utils/websocket.utils';
import {
  DEVICE_TAG,
  DataPoint,
  DeviceByFirmwareIdResponse,
  DeviceDetail,
  DeviceProfilesDetailsByProfileUUIDResponse,
  DeviceProfilesDetailsByCompatibleDeviceTypeApiTransformedResponse,
  FetchDefaultScheduleConfigArgs,
  FetchDefaultScheduleConfigResponse,
  FetchDeviceArgs,
  FetchDeviceDatapointsArgs,
  FetchDeviceEventsTrendArgs,
  FetchDeviceEventsTrendResponse,
  FetchDeviceHistoricalDataWithoutSamplingArgs,
  FetchDeviceProfilesDetailsByCompatibleDeviceTypeArgs,
  FetchDeviceProfilesDetailsByDeviceProfileIdArgs,
  FetchDeviceWidgetSettingArgs,
  FetchDeviceWidgetSettingResponse,
  FetchOrbitPlotDataArgs,
  FetchOrbitPlotDataResponse,
  FetchStandaloneDevicesArgs,
  GetDeviceTypesArgs,
  GetDevicesArgs,
  GetDevicesByFirmwareIdArgs,
  FetchDeviceSensorTemplatesArgs,
  DeviceSensorTemplatesResponse,
  CreateDeviceArgs,
  FetchPowertrainComponentsArgs,
  FetchPowertrainDetailArgs,
  PowertrainDetailResponse,
  UpdatePowertrainArgs,
  UpdatePowertrainResponse,
  CreatePowertrainArgs,
  CreatePowertrainResponse,
  DeletePowertrainArgs,
  CreateGroupArgs,
  CreateGroupResponse,
  EditGroupArgs,
  EditGroupResponse,
  DeleteGroupArgs,
  DeleteGroupResponse,
  MoveGroupToGroupArgs,
  MoveGroupToGroupResponse,
  MoveDeviceToGroupArgs,
  MoveDeviceToGroupResponse,
  FetchMultipleDevicePhotosArgs,
  DevicePhotoList,
  Photo,
  GetDeviceProfilesArgs,
  DeviceProfilesApiResponse,
  UpdateDeviceProfileArgs,
  UpdateDeviceProfileResponse,
  DeleteDevicePhotoArgs,
  DeleteDevicePhotoResponse,
  ReplaceDevicePhotoArgs,
  ReplaceDevicePhotoResponse,
  UploadDevicePhotoArgs,
  UploadDevicePhotoResponse,
  DeleteDeviceArgs,
  DeleteDeviceResponse,
  UpdateDeviceSimSettingsArgs,
  UpdateDeviceSimSettingsResponse,
  UpdateDeviceSettingsArgs,
  UpdateDeviceFirmwareArgs,
  UpdateDeviceFirmwareResponse,
  RebootDeviceArgs,
  RebootDeviceResponse,
  FetchCategoriesArgs,
  FetchCategoriesResponse,
  ActivityLogsCategory,
  FetchAdditionalSensorsArgs,
  SensorData,
  AdditionalSensorsResponse,
  CreateDeviceWidgetsSettingArgs,
  CreateDeviceWidgetsSettingResponse,
  HistoricalDataWithoutSampling,
  UpdateDeviceWidgetSettingArgs,
  UpdateDeviceWidgetSettingResponse,
  Widget,
  FetchDeviceGroupsArgs,
  FetchMultipleDeviceDatapointsArgs,
  FetchMultipleDeviceDatapointsResponse,
  DatapointResponse,
  FetchRawDatapointsByDeviceIdsArgs,
  FetchHistoricalTrendDataArgs,
  RawDataPointsResponse,
  HistoricalTrendDataResponse,
  FetchBatteryStatusArgs,
  BatteryStatusResponse,
  AnomalyListResponse,
  FetchAnomalyListQueryArgs,
  FetchLatestPowetrainConfigArgs,
  FetchLatestPowetrainConfigResponse,
  DeviceMailboxMessage,
  FetchDeviceMailboxArgs,
  FetchAlertsArgs,
  AlertsResponse
} from './device.types';
import {
  getFormatedList,
  setNewDeviceProperties,
  setNewGroupProperties,
  setPhotosData
} from './device.utils';
import { PowertrainComponent } from './powertrain';
import { Device, DeviceType, Group } from './types';
import {
  GenericDashboardResponse,
  MergedFetchGenericDashboardArgs,
  GenericDashboardType,
  FetchAssetWidgetDataArgs,
  FetchAssetWidgetDataResponse,
  FetchImageWidgetDataArgs,
  FetchImageWidgetDataResponse,
  FetchSensorWidgetDataArgs,
  FetchSensorWidgetDataResponse,
  UpdateSensorWidgetDataArgs,
  UpdateSensorWidgetDataResponse
} from './types/genericDashboard.types';

import { USER_TAG_TYPES } from '../../org-settings/store';

const TIMEOUT_FOR_CACHED_API = 300;

export const UPDATE_DEVICE_PROFILE_SHARED_RESULT = 'UPDATE_DEVICE_PROFILE_SHARED_RESULT';
export const UPDATE_DEVICE_SIM_SETTINGS_SHARED_RESULT = 'UPDATE_DEVICE_SIM_SETTINGS_SHARED_RESULT';

export const deviceApi = featureApi.injectEndpoints({
  endpoints: (build) => ({
    fetchDevice: build.query<DeviceDetail, FetchDeviceArgs>({
      query: fetchDeviceQuery,
      keepUnusedDataFor: TIMEOUT_FOR_CACHED_API,
      providesTags: (result) => [{ id: result?.id, type: DEVICE_TAG.DEVICE }]
    }),
    fetchDevices: build.query<Device[], GetDevicesArgs>({
      query: fetchDevicesQuery,
      keepUnusedDataFor: TIMEOUT_FOR_CACHED_API,
      transformResponse: (response: { results: Device[] }) =>
        setNewDeviceProperties(response?.results),
      async onCacheEntryAdded(arg, options) {
        await setupWebSocketListener<Device[], GetDevicesArgs>(deviceMessageHandler, arg, options);
      },
      providesTags: (result) => generateTags(result, DEVICE_TAG.DEVICES)
    }),
    fetchDeviceGroups: build.query<Group[], FetchDeviceGroupsArgs>({
      query: fetchDeviceGroupsQuery,
      keepUnusedDataFor: TIMEOUT_FOR_CACHED_API,
      transformResponse: (response: { results: Group[] }) =>
        setNewGroupProperties(response?.results),
      async onCacheEntryAdded(arg, options) {
        await setupWebSocketListener<Group[], FetchDeviceGroupsArgs>(
          groupsMessageHandler,
          arg,
          options
        );
      },
      providesTags: (result) => generateTags(result, DEVICE_TAG.DEVICE_GROUPS)
    }),
    fetchStandaloneDevices: build.query<Device[], FetchStandaloneDevicesArgs>({
      query: fetchStandaloneDevicesQuery,
      keepUnusedDataFor: TIMEOUT_FOR_CACHED_API,
      transformResponse: (response: { results: Device[] }) => response?.results,
      async onCacheEntryAdded(arg, options) {
        await setupWebSocketListener<Device[], GetDevicesArgs>(
          standaloneDevicesMessageHandler,
          arg,
          options
        );
      },
      providesTags: (result) => generateTags(result, DEVICE_TAG.STANDALONE_DEVICES)
    }),
    fetchDevicesByFirmwareId: build.query<DeviceByFirmwareIdResponse, GetDevicesByFirmwareIdArgs>({
      query: fetchDevicesByFirmwareIdQuery,
      providesTags: (result) => generateTags(result?.results, DEVICE_TAG.DEVICE_FIRMWARE_BY_ID)
    }),
    fetchMultipleDeviceDatapoints: build.query<
      Array<DatapointResponse>,
      FetchMultipleDeviceDatapointsArgs
    >({
      query: fetchMultipleDeviceDatapointsQuery,
      transformResponse: (response: FetchMultipleDeviceDatapointsResponse) => response.datapoints
      // providesTags: (result) => generateTags(result, DEVICE_TAG.MULTI_DEVICE_DATAPOINTS, 'dpids')
    }),
    fetchDeviceDatapoints: build.query<DataPoint[], FetchDeviceDatapointsArgs>({
      query: fetchDeviceDatapointsQuery,
      providesTags: (result) => generateTags(result, DEVICE_TAG.DEVICE_DATAPOINTS, 'dpid')
    }),
    fetchDeviceTypes: build.query<DeviceType[], GetDeviceTypesArgs>({
      query: fetchDevicesTypesQuery,
      transformResponse: (response: { results: DeviceType[] }) => response?.results,
      providesTags: (result) => generateTags(result, DEVICE_TAG.DEVICE_TYPES)
    }),
    fetchDeviceProfilesDetailsByCompatibleDeviceType: build.query<
      DeviceProfilesDetailsByCompatibleDeviceTypeApiTransformedResponse,
      FetchDeviceProfilesDetailsByCompatibleDeviceTypeArgs
    >({
      query: fetchDeviceProfilesDetailsByCompatibleDeviceTypeQuery,
      transformResponse: (
        response: DeviceProfilesDetailsByCompatibleDeviceTypeApiTransformedResponse
      ) => {
        const { formattedProfileList, defaultProfile } = getFormatedList(response.results);
        return {
          ...response,
          listProfile: formattedProfileList,
          defaultProfile
        };
      },
      providesTags: (result) =>
        generateTags(result?.results, DEVICE_TAG.DEVICE_PROFILES_DETAILS_BY_COMPATIBLE_DEVICE_TYPE)
    }),
    fetchDeviceProfilesDetailsByDeviceProfileId: build.query<
      DeviceProfilesDetailsByProfileUUIDResponse,
      FetchDeviceProfilesDetailsByDeviceProfileIdArgs
    >({
      query: fetchDeviceProfilesDetailsByDeviceProfileIdQuery,
      providesTags: (result) =>
        generateTags(result, DEVICE_TAG.DEVICE_PROFILES_DETAILS_BY_DEVICE_PROFILE_ID)
    }),
    updateDeviceProfile: build.mutation<UpdateDeviceProfileResponse, UpdateDeviceProfileArgs>({
      query: updateDeviceProfileQuery,
      invalidatesTags: () => [
        DEVICE_TAG.DEVICE_PROFILES_DETAILS_BY_DEVICE_PROFILE_ID,
        DEVICE_TAG.POWERTRAIN_DETAIL,
        DEVICE_TAG.LATEST_POWERTRAIN_CONFIG
      ]
    }),
    fetchDeviceProfiles: build.query<DeviceProfilesApiResponse, GetDeviceProfilesArgs>({
      query: fetchDeviceProfilesQuery,
      providesTags: (result) => generateTags(result, DEVICE_TAG.DEVICE_PROFILES)
    }),
    fetchDeviceSensorTemplates: build.query<
      DeviceSensorTemplatesResponse,
      FetchDeviceSensorTemplatesArgs
    >({
      // Making multiple API calls below in a single query.
      async queryFn(arg, { dispatch }) {
        return fetchDeviceDeviceSensorTemplatesQuery(
          arg,
          dispatch,
          DEVICE_TAG.DEVICE_SENSOR_TEMPLATES
        );
      },
      providesTags: (result) => generateTags(result, DEVICE_TAG.DEVICE_SENSOR_TEMPLATES)
    }),
    createDevice: build.mutation<DeviceDetail, CreateDeviceArgs>({
      query: createDeviceQuery
    }),
    deleteDevice: build.mutation<DeleteDeviceResponse, DeleteDeviceArgs>({
      query: deleteDeviceQuery
    }),
    updateDeviceSimSettings: build.mutation<
      UpdateDeviceSimSettingsResponse,
      UpdateDeviceSimSettingsArgs
    >({
      query: updateDeviceSimSettingsQuery
    }),
    updateDeviceSettings: build.mutation<DeviceDetail, UpdateDeviceSettingsArgs>({
      query: updateDeviceSettingsQuery
    }),
    updateDeviceFirmware: build.mutation<UpdateDeviceFirmwareResponse, UpdateDeviceFirmwareArgs>({
      query: updateDeviceFirmwareQuery
    }),
    rebootDevice: build.mutation<RebootDeviceResponse, RebootDeviceArgs>({
      query: rebootDeviceQuery
    }),
    createGroup: build.mutation<CreateGroupResponse, CreateGroupArgs>({
      query: createGroupQuery,
      invalidatesTags: [USER_TAG_TYPES.USER_GROUP]
    }),
    editGroup: build.mutation<EditGroupResponse, EditGroupArgs>({
      query: editGroupQuery,
      invalidatesTags: [USER_TAG_TYPES.USER_GROUP]
    }),
    deleteGroup: build.mutation<DeleteGroupResponse, DeleteGroupArgs>({
      query: deleteGroupQuery,
      invalidatesTags: [USER_TAG_TYPES.USER_GROUP]
    }),
    moveGroupToGroup: build.mutation<MoveGroupToGroupResponse, MoveGroupToGroupArgs>({
      query: moveGroupToGroupQuery,
      invalidatesTags: [USER_TAG_TYPES.USER_GROUP]
    }),
    moveDeviceToGroup: build.mutation<MoveDeviceToGroupResponse, MoveDeviceToGroupArgs>({
      query: moveDeviceToGroupQuery,
      invalidatesTags: [USER_TAG_TYPES.USER_GROUP]
    }),
    // Powertrain related API started.
    fetchPowertrainComponents: build.query<PowertrainComponent[], FetchPowertrainComponentsArgs>({
      query: fetchPowertrainComponentsQuery,
      providesTags: (result) => generateTags(result, DEVICE_TAG.POWERTRAIN_COMPONENTS)
    }),
    fetchPowertrainDetail: build.query<PowertrainDetailResponse, FetchPowertrainDetailArgs>({
      query: fetchPowertrainDetailQuery,
      transformResponse: (response: PowertrainDetailResponse) => {
        return {
          ...response,
          assets: response?.assets?.map((asset) => ({
            ...asset,
            asset_id: asset.id
          }))
        };
      },
      providesTags: (result, args, arg) => [
        {
          type: DEVICE_TAG.POWERTRAIN_DETAIL,
          id: Number(arg?.powertrainId)
        }
      ]
    }),
    fetchLatestPowetrainConfig: build.query<
      FetchLatestPowetrainConfigResponse,
      FetchLatestPowetrainConfigArgs
    >({
      query: fetchLatestPowetrainConfigQuery,
      providesTags: (result) => generateTags(result, DEVICE_TAG.LATEST_POWERTRAIN_CONFIG)
    }),
    updatePowertrain: build.mutation<UpdatePowertrainResponse, UpdatePowertrainArgs>({
      query: updatePowertrainQuery,
      invalidatesTags: [USER_TAG_TYPES.USER_GROUP]
    }),
    createPowertrain: build.mutation<CreatePowertrainResponse, CreatePowertrainArgs>({
      query: createPowertrainQuery,
      invalidatesTags: [USER_TAG_TYPES.USER_GROUP]
    }),
    deletePowertrain: build.mutation<any, DeletePowertrainArgs>({
      query: deletePowertrainQuery,
      invalidatesTags: [USER_TAG_TYPES.USER_GROUP]
    }),
    // Powertrain related API ended.
    fetchGenericDashboard: build.query<
      GenericDashboardResponse,
      MergedFetchGenericDashboardArgs<GenericDashboardType>
    >({
      query: fetchGenericDashboardQuery,
      providesTags: (result) => generateTags(result?.dashboard_type, DEVICE_TAG.GENERIC_DASHBOARD)
    }),
    fetchAssetWidgetData: build.query<FetchAssetWidgetDataResponse, FetchAssetWidgetDataArgs>({
      query: fetchAssetWidgetDataQuery,
      providesTags: (result, _err, arg) => [
        { type: DEVICE_TAG.ASSET_WIDGET_DATA, id: arg?.widget_uuid }
      ],
      async onCacheEntryAdded(arg, options) {
        await setupWebSocketListener<FetchAssetWidgetDataResponse, FetchAssetWidgetDataArgs>(
          assetWidgetDataMessageHandler,
          arg,
          options
        );
      }
    }),
    fetchSensorWidgetData: build.query<FetchSensorWidgetDataResponse, FetchSensorWidgetDataArgs>({
      query: fetchSensorWidgetDataQuery,
      providesTags: (result, _err, arg) => [
        { type: DEVICE_TAG.SENSOR_WIDGET_DATA, id: arg?.widget_uuid }
      ]
    }),
    fetchImageWidgetData: build.query<FetchImageWidgetDataResponse, FetchImageWidgetDataArgs>({
      async queryFn(arg, { dispatch }) {
        return fetchImageWidgetDataQuery(arg, dispatch, DEVICE_TAG.IMAGE_WIDGET_DATA);
      },
      providesTags: (result, _err, arg) => [
        { type: DEVICE_TAG.IMAGE_WIDGET_DATA, id: arg?.assetPath }
      ]
    }),
    updateSensorWidgetData: build.mutation<
      UpdateSensorWidgetDataResponse,
      UpdateSensorWidgetDataArgs
    >({
      query: updateSensorWidgetDataQuery,
      invalidatesTags: [DEVICE_TAG.SENSOR_WIDGET_DATA, DEVICE_TAG.GENERIC_DASHBOARD]
    }),
    fetchDeviceEventsTrend: build.query<FetchDeviceEventsTrendResponse, FetchDeviceEventsTrendArgs>(
      {
        query: fetchDeviceEventsTrendQuery,
        providesTags: (result) => generateTags(result?.data, DEVICE_TAG.DEVICE_EVENTS)
      }
    ),
    fetchOrbitPlotData: build.query<FetchOrbitPlotDataResponse, FetchOrbitPlotDataArgs>({
      query: fetchOrbitPlotDataQuery,
      providesTags: (result) => generateTags(result, DEVICE_TAG.ORBIT_CHART)
    }),
    fetchDefaultScheduleConfig: build.query<
      FetchDefaultScheduleConfigResponse,
      FetchDefaultScheduleConfigArgs
    >({
      query: fetchDefaultScheduleConfigQuery,
      providesTags: (result) => generateTags(result, DEVICE_TAG.DEVICE_SCHEDULE_CONFIG)
    }),
    fetchHistoricalDataWithoutSampling: build.query<
      HistoricalDataWithoutSampling,
      FetchDeviceHistoricalDataWithoutSamplingArgs
    >({
      query: fetchHistoricalDataWithoutSamplingQuery,
      transformResponse: (response: { data: HistoricalDataWithoutSampling }) => {
        /**
         * Reversing the order of datapoints (i.e. From oldest to newest)
         */
        if (response?.data && Object.entries(response?.data)?.length > 0) {
          Object.entries(response?.data)?.forEach(([key, value]) => {
            if (value && value?.length > 0) {
              response.data[key] = value?.reverse();
            } else {
              response.data[key] = [];
            }
          });
        }
        return response?.data || undefined;
      },
      providesTags: (result) =>
        generateTags(result, DEVICE_TAG.DEVICE_HISTORICAL_DATA_WITHOUT_SAMPLING)
    }),
    fetchDeviceWidgetSetting: build.query<Widget[], FetchDeviceWidgetSettingArgs>({
      query: fetchDeviceWidgetSetting,
      transformResponse: (response: FetchDeviceWidgetSettingResponse) => {
        return response?.widget;
      },
      providesTags: (result) => generateTags(result, DEVICE_TAG.DEVICE_WIDGET_SETTING)
    }),
    updateDeviceWidgetSetting: build.mutation<
      UpdateDeviceWidgetSettingResponse,
      UpdateDeviceWidgetSettingArgs
    >({
      query: updateDeviceWidgetSetting,
      invalidatesTags: [{ type: DEVICE_TAG.DEVICE_WIDGET_SETTING }]
    }),
    createDeviceWidgetsSetting: build.mutation<
      CreateDeviceWidgetsSettingResponse,
      CreateDeviceWidgetsSettingArgs
    >({
      query: createDeviceWidgetsSetting,
      invalidatesTags: [{ type: DEVICE_TAG.DEVICE_WIDGET_SETTING }]
    }),
    fetchMultipleDevicePhotos: build.query<Photo[], FetchMultipleDevicePhotosArgs>({
      query: fetchMultipleDevicePhotosQuery,
      keepUnusedDataFor: TIMEOUT_FOR_CACHED_API,
      transformResponse: (response: DevicePhotoList) => {
        return setPhotosData(response);
      },
      providesTags: (result) => generateTags(result, DEVICE_TAG.DEVICE_PHOTOS)
    }),
    deleteDevicePhoto: build.mutation<DeleteDevicePhotoResponse, DeleteDevicePhotoArgs>({
      query: deleteDevicePhotoQuery,
      invalidatesTags: () => [DEVICE_TAG.DEVICE_PHOTOS]
    }),
    replaceDevicePhoto: build.mutation<ReplaceDevicePhotoResponse, ReplaceDevicePhotoArgs>({
      async queryFn(arg, { dispatch }) {
        return replaceDevicePhotoQuery(arg, dispatch, DEVICE_TAG.REPLACE_DEVICE_PHOTO);
      },
      invalidatesTags: () => [DEVICE_TAG.DEVICE_PHOTOS]
    }),
    uploadDevicePhoto: build.mutation<UploadDevicePhotoResponse, UploadDevicePhotoArgs>({
      async queryFn(arg, { dispatch }) {
        return uploadDevicePhotoQuery(arg, dispatch, DEVICE_TAG.UPLOAD_DEVICE_PHOTO);
      },
      invalidatesTags: () => [DEVICE_TAG.DEVICE_PHOTOS]
    }),
    fetchCategories: build.query<ActivityLogsCategory[], FetchCategoriesArgs>({
      query: fetchCategoriesQuery,
      transformResponse(response: FetchCategoriesResponse) {
        return response?.results;
      },
      providesTags: (result) => generateTags(result, DEVICE_TAG.CATEGORIES)
    }),
    fetchAdditionalSensors: build.query<SensorData[], FetchAdditionalSensorsArgs>({
      query: fetchAdditionalSensorsQuery,
      transformResponse(response: AdditionalSensorsResponse) {
        return response?.results;
      },
      providesTags: (result) => generateTags(result, DEVICE_TAG.ADDITIONAL_SENSORS)
    }),
    fetchRawDatapointsByDeviceIds: build.query<
      RawDataPointsResponse,
      FetchRawDatapointsByDeviceIdsArgs
    >({
      query: fetchRawDatapointsByDeviceIdsQuery,
      providesTags: (result) => generateTags(result, DEVICE_TAG.RAW_DATAPOINTS_BY_DEVICE_IDS)
    }),
    fetchHistoricalTrendData: build.query<
      HistoricalTrendDataResponse,
      FetchHistoricalTrendDataArgs
    >({
      query: fetchHistoricalTrendDataQuery,
      providesTags: (result) => generateTags(result, DEVICE_TAG.HISTORICAL_TREND_DATA)
    }),
    fetchBatteryStatus: build.query<BatteryStatusResponse[], FetchBatteryStatusArgs>({
      query: fetchBatteryStatusQuery,
      providesTags: (result) => generateTags(result, DEVICE_TAG.BATTERY_STATUS)
    }),
    fetchAnomalyList: build.query<AnomalyListResponse, FetchAnomalyListQueryArgs>({
      query: fetchAnomalyListQuery,
      providesTags: (result) =>
        generateTags(result?.derived_expressions, DEVICE_TAG.ANOMALY_LIST, 'derived_dp_id')
    }),
    fetchDeviceMailbox: build.query<DeviceMailboxMessage[], FetchDeviceMailboxArgs>({
      query: fetchDeviceMailboxQuery,
      transformResponse: (response: { message_list: DeviceMailboxMessage[] }) => {
        return response?.message_list || [];
      },
      providesTags: (result) => generateTags(result, DEVICE_TAG.DEVICE_MAILBOX)
    }),
    fetchAlerts: build.query<AlertsResponse, FetchAlertsArgs>({
      query: fetchAlertsQuery,
      providesTags: (result) => generateTags(result, DEVICE_TAG.ALERTS)
    })
  })
});

export const {
  useFetchDevicesByFirmwareIdQuery,
  useLazyFetchMultipleDeviceDatapointsQuery,
  useFetchMultipleDeviceDatapointsQuery,
  useLazyFetchDeviceDatapointsQuery,
  useFetchDeviceDatapointsQuery,
  useFetchDevicesQuery,
  useLazyFetchDevicesQuery,
  useFetchStandaloneDevicesQuery,
  useLazyFetchStandaloneDevicesQuery,
  useFetchDeviceGroupsQuery,
  useLazyFetchDeviceGroupsQuery,
  useLazyFetchDeviceEventsTrendQuery,
  useFetchDeviceEventsTrendQuery,
  useFetchOrbitPlotDataQuery,
  useLazyFetchOrbitPlotDataQuery,
  useFetchDefaultScheduleConfigQuery,
  useLazyFetchDefaultScheduleConfigQuery,
  useFetchHistoricalDataWithoutSamplingQuery,
  useLazyFetchHistoricalDataWithoutSamplingQuery,
  useFetchDeviceWidgetSettingQuery,
  useLazyFetchDeviceWidgetSettingQuery,
  useFetchDeviceQuery,
  useLazyFetchDeviceQuery,
  useFetchDeviceTypesQuery,
  useFetchDeviceProfilesDetailsByCompatibleDeviceTypeQuery,
  useFetchDeviceProfilesDetailsByDeviceProfileIdQuery,
  useCreateDeviceMutation,
  useFetchPowertrainComponentsQuery,
  useFetchPowertrainDetailQuery,
  useLazyFetchPowertrainDetailQuery,
  useUpdatePowertrainMutation,
  useCreatePowertrainMutation,
  useDeletePowertrainMutation,
  useCreateGroupMutation,
  useEditGroupMutation,
  useDeleteGroupMutation,
  useMoveGroupToGroupMutation,
  useMoveDeviceToGroupMutation,
  useFetchGenericDashboardQuery,
  useFetchAssetWidgetDataQuery,
  useFetchImageWidgetDataQuery,
  useLazyFetchImageWidgetDataQuery,
  useFetchSensorWidgetDataQuery,
  useUpdateSensorWidgetDataMutation,
  useFetchMultipleDevicePhotosQuery,
  useLazyFetchMultipleDevicePhotosQuery,
  useFetchDeviceProfilesQuery,
  useFetchDeviceSensorTemplatesQuery,
  useUpdateDeviceProfileMutation,
  useDeleteDevicePhotoMutation,
  useReplaceDevicePhotoMutation,
  useUploadDevicePhotoMutation,
  useDeleteDeviceMutation,
  useUpdateDeviceSimSettingsMutation,
  useUpdateDeviceSettingsMutation,
  useUpdateDeviceFirmwareMutation,
  useRebootDeviceMutation,
  useFetchCategoriesQuery,
  useFetchAdditionalSensorsQuery,
  useUpdateDeviceWidgetSettingMutation,
  useCreateDeviceWidgetsSettingMutation,
  useFetchRawDatapointsByDeviceIdsQuery,
  useFetchHistoricalTrendDataQuery,
  useLazyFetchHistoricalTrendDataQuery,
  useFetchBatteryStatusQuery,
  useFetchAnomalyListQuery,
  useFetchLatestPowetrainConfigQuery,
  useFetchDeviceMailboxQuery,
  useFetchAlertsQuery
} = deviceApi;
