import { Dispatch, UnknownAction } from '@reduxjs/toolkit';
import { Device, Group } from '../../store';
import { moveBreadcrumbToRight, popFromBreadcrumb, pushToBreadcrumb } from '../../../../framework';
import { FetchDeviceGroupsArgsPayload, RawDataPointsResponse } from '../../store/device.types';
import { SyntheticEvent } from 'react';
import { SubOrgListState } from '../../../organization/store';
import { checkAndGetSubOrgName } from '../../device-list/grouped-list/groupedDeviceList.utils';
import { BREADCRUMB_ACTION } from '../../../../framework/components/breadcrumb/types/breadcrumbState';
import { NavigateFunction, Location } from 'react-router-dom';
import { updateQueryParameters } from '../../../workflow/trend-analysis/orbit-plot';
import {
  FullScreenWidget,
  NEW_ASSET_DASHBOARD_URL_PARAMS
} from '../custom-hooks/useGetNewAssetDashboardUrlParams';

export const DATAPOINTS_NAME_SPLITTER = '*';

/**
 * Generates the payload for the groups API used in the new asset dashboard.
 *
 * The payload includes:
 * - `alarm_status`: A boolean indicating whether to include the alarm status of the device groups.
 * - `last_connected`: A boolean indicating whether to include the last connected status of the device groups.
 *
 * These fields are essential for the new asset dashboard to display relevant information
 * about the device groups. By generating this payload, we ensure that the necessary data
 * is fetched in a single API call, promoting reusability and efficiency across different
 * components that need this information.
 *
 * @returns The payload containing the required fields for the groups API.
 */
export const generatePayloadForGroupsApiForNewAssetDashboard = (): FetchDeviceGroupsArgsPayload => {
  const groupsApiPayload: FetchDeviceGroupsArgsPayload = {
    alarm_status: true,
    last_connected: true
  };

  return groupsApiPayload;
};

/**
 * Add trailing char to the selected powertrain path, so that all the assets
 * inside of the powertrain will be selected.
 * @param selectedPowertrainPath - The selected powertrain path
 * @returns
 */
export function addTrailingChar(selectedPowertrainPath: string) {
  return `${selectedPowertrainPath}.*` as const;
}

export function getDevicesIds(devices: Device[]): string {
  return devices.map((device) => device.id).join(',');
}

/**
 * Manage breadcrumbs for selected powertrain path
 * @param dispatch
 * @param selectedPowertrainPath - The selected powertrain path from URL
 */
export const updatePowertrainPathNameToBreadcrumbRecursively = (
  dispatch: Dispatch<UnknownAction>,
  selectedPowertrainPath: string,
  deviceGroupsMap: Record<number, Group>,
  action?: BREADCRUMB_ACTION
) => {
  if (selectedPowertrainPath) {
    const pathSegments = selectedPowertrainPath.split('.');
    for (const segment of pathSegments) {
      if (deviceGroupsMap && deviceGroupsMap[Number(segment)]) {
        if (action === BREADCRUMB_ACTION.ADD) {
          dispatch(
            pushToBreadcrumb({
              key: deviceGroupsMap[Number(segment)]?.name,
              title: deviceGroupsMap[Number(segment)]?.name,
              link: undefined
            })
          );
        } else if (action === BREADCRUMB_ACTION.REMOVE) {
          dispatch(popFromBreadcrumb(deviceGroupsMap[Number(segment)]?.name));
          dispatch(moveBreadcrumbToRight({ shouldMoveToRight: false }));
        }
      }
    }
  }
};

export const setImageAspectRatio = (
  event: SyntheticEvent<HTMLDivElement, Event>,
  parentNodeClientHeight: number,
  differenceInPx: number,
  setDimensions: (
    value: React.SetStateAction<{
      height: number;
      width: number;
    }>
  ) => void
) => {
  const containerHeight = parentNodeClientHeight - differenceInPx;

  const target = event.target as HTMLImageElement;
  const imageHeight = target.naturalHeight;
  const imageWidth = target.naturalWidth;

  const aspectRatio = imageWidth / imageHeight;

  setDimensions({
    height: containerHeight,
    width: containerHeight * aspectRatio
  });
};

export const addNecessaryPropsToAssets = (
  devices: Device[],
  subOrgListState: SubOrgListState,
  orgName: string | undefined
) => {
  const devicesWithDeviceTypeAndSite: Device[] = [];

  devices.forEach((device) => {
    const restructuredDevice = JSON.parse(JSON.stringify(device));

    restructuredDevice.device_type =
      device.device_type === 'dcp_compressor' || device.device_type === 'estis_compressor'
        ? 'External Data'
        : device.device_type;

    restructuredDevice.site = checkAndGetSubOrgName(device.suborg_uuid, subOrgListState, orgName);

    devicesWithDeviceTypeAndSite.push(restructuredDevice);
  });

  return devicesWithDeviceTypeAndSite;
};

/**
 * Returns an array of parent keys from the selected powertrain path.
 * @returns {string[]} Array of parent keys.
 */
export const getDefaultExpandedKeys = (selectedPowertrainPath: string | null): string[] => {
  // Check if selectedPowertrainPath exists in urlParams
  if (selectedPowertrainPath) {
    // Split the selectedPowertrainPath into segments
    const pathSegments = selectedPowertrainPath?.split('.');

    // Map through the segments, excluding the last segment, to build parent keys
    const parentKeys = pathSegments
      .slice(0, -1)
      .map((_, index) => pathSegments.slice(0, index + 1).join('.'));
    return parentKeys;
  }

  // Return an empty array if selectedPowertrainPath does not exist
  return [];
};

/**
 * Filters devices based on the selected powertrain path.
 *
 * This function ensures that devices or powertrains added via websocket are filtered
 * according to the currently selected powertrain path. This filtering is crucial when
 * the devices API cache is updated through RTK Query hook subscriptions, ensuring that
 * only relevant devices are displayed.
 *
 * The filtering logic checks if a device's fully qualified path (`fq_dg_path`) either starts with
 * or exactly matches the selected powertrain path, allowing for both hierarchical and direct matches.
 *
 * For more details on the rationale behind this filtering, refer to the Jira ticket:
 * https://shorelineiot.atlassian.net/browse/SLC-9779
 *
 * @param {Device[]} devices - The array of devices to filter.
 * @param {string | null} selectedPowertrainPath - The currently selected powertrain path.
 * @returns {Device[]} - An array of devices that belong to the selected powertrain.
 */
export const filterDevicesInSelectedPowertrain = (
  devices: Device[],
  selectedPowertrainPath: string | null
): Device[] => {
  return devices?.filter(
    (device) =>
      device?.fq_dg_path?.startsWith(`${selectedPowertrainPath}.`) ||
      device?.fq_dg_path === selectedPowertrainPath
  );
};

export const addRequiredPropsToGroups = (
  groups: Group[],
  subOrgListState: SubOrgListState,
  orgName: string | undefined
) => {
  return groups?.map((group) => ({
    ...group,
    // `site` property is required for `MoveToComponent` component.
    site: checkAndGetSubOrgName(group.suborg_uuid, subOrgListState, orgName)
  }));
};

/**
 * Excluding external devices from the list because they don't have datapoints
 * that are common with other `iCastSense` devices.
 * This is specially required for the Trends widget as per `TrendsDatapointSelection.tsx` component logic,
 * otherwise datapoints dropdown options would be empty.
 */
export const removeExternalDevices = (devices: Device[]): Device[] => {
  return devices?.filter((device) => !device?.ext_datasource);
};

export const extractCommonDatapointsForTrends = (rawDatapointsResponse: RawDataPointsResponse) => {
  // Object to keep track of the occurrence of each datapoint across all device arrays
  const datapointCount: { [key: string]: { count: number; title: string; value: string } } = {};

  // Iterate over each array of datapoints associated with a device
  rawDatapointsResponse?.datapoints?.forEach((item) => {
    // Filter out unwanted sensor types and count occurrences of each datapoint
    item.datapoints
      .filter(
        (dp) =>
          dp.sensor_type !== 'sns_accelerometer' &&
          dp.sensor_type !== 'sns_mic_struct_borne' &&
          dp.datatype !== 'string'
      )
      .forEach((dp) => {
        if (datapointCount[dp.datapoint_type]) {
          datapointCount[dp.datapoint_type].count += 1;
        } else {
          datapointCount[dp.datapoint_type] = {
            count: 1,
            title: dp.dp_name,
            value: dp.dp_name
          };
        }
      });
  });

  // Get the total number of device arrays to determine common datapoints
  const deviceCount = rawDatapointsResponse?.datapoints?.length;

  // Filter to keep only those datapoints that are present in every device array
  const commonDatapoints = Object.values(datapointCount)
    .filter((datapoint) => datapoint.count === deviceCount)
    ?.sort((a, b) => a?.title?.localeCompare(b?.title));

  return commonDatapoints;
};

export const handleCustomFullScreenChange = ({
  data,
  location,
  navigate,
  fullScreenWidget
}: {
  data: boolean;
  location: Location;
  navigate: NavigateFunction;
  fullScreenWidget: FullScreenWidget;
}) => {
  if (data) {
    updateQueryParameters(location, navigate, {
      [NEW_ASSET_DASHBOARD_URL_PARAMS.FULL_SCREEN_WIDGET]: fullScreenWidget
    });
  } else {
    updateQueryParameters(location, navigate, undefined, [
      NEW_ASSET_DASHBOARD_URL_PARAMS.FULL_SCREEN_WIDGET
    ]);
  }
};
