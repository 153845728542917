/**
 * Authenticated Route
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { ReactNode, useEffect } from 'react';
import { Route, RouteProps, useNavigate, useLocation, Navigate, Routes } from 'react-router-dom';
import { useSlugContext } from './slugContext';
import { LoginStateType } from '../../features/auth/login/store/types/LoginStateType';
import { useLoginState } from '../../features/auth/login/store/reducers/login.selectorHooks';

interface Props {
  children: ReactNode;
}

export function AuthenticatedRoute({ children, ...rest }: Props & RouteProps) {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const { slug } = useSlugContext();
  const login: LoginStateType = useLoginState();
  useEffect(() => {
    // checking path with // and removing it if found.
    if (pathname.match(/\/{2,}/g)) {
      navigate(
        {
          pathname: pathname.replace(/\/{2,}/g, '/'),
          search
        },
        {
          replace: true
        }
      );
    }
  }, [pathname]);

  return (
    <Routes>
      <Route
        path="*"
        element={
          //Even if the user is not logged in, we should allow the user to access the no-org page if the path is no-org
          login.status === 'LOGGED_IN' || pathname.includes('no-org') ? (
            children
          ) : (
            <Navigate
              to={`/${slug}/auth/login?redirect=${pathname}${search}`}
              replace
              state={{ from: rest.path }}
            />
          )
        }
      />
    </Routes>
  );
}
