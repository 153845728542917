import { Amplify } from 'aws-amplify';
import axios from 'axios';
import {
  VITE_AUTH_USER_POOL_WEB_CLIENT_SECRET,
  VITE_AUTH_REGION,
  VITE_AUTH_USER_POOL_WEB_CLIENT,
  VITE_AUTH_USER_POOL_DOMAIN,
  VITE_HOST_API,
  VITE_HOST_URL,
  VITE_DEVICE_API,
  VITE_DEVICE_URL,
  VITE_EVAL_EXPR_URL,
  VITE_MQTT_ID,
  VITE_ACTIVITY_LOGS_API,
  VITE_ACTIVITY_LOGS_URL,
  VITE_CHAT_API,
  VITE_CHAT_URL,
  VITE_VA_API,
  VITE_VA_URL,
  VITE_BASE_PATH,
  VITE_EVAL_EXPR_API,
  VITE_EXPORT_URL
} from './envConstants';
import {
  confirmSignIn,
  fetchAuthSession,
  getCurrentUser,
  resendSignUpCode,
  signIn,
  signOut,
  signUp,
  updatePassword,
  SignUpOutput,
  SignInOutput,
  ConfirmSignInOutput,
  confirmResetPassword,
  resetPassword
} from 'aws-amplify/auth';
import { LibraryOptions, ResourcesConfig } from '@aws-amplify/core';
import { get, patch, post, put, del } from 'aws-amplify/api';
import { PubSub } from '@aws-amplify/pubsub';
/**
 * Auth Service
 * @authors mahesh.kedari@shorelineiot.com, priyanka.ambawane@shorelineiot.com
 */
interface AuthType {}

interface SignUpUser {
  name: string;
  givenName?: string;
  familyName?: string;
  email: string;
  password: string;
  retypePassword: string;
  country_code: string;
  org_name: string;
  slug: string;
}
/**
 *
 */
interface SignInUser {
  email: string;
  password: string;
}
/**
 *
 */
export interface AuthResponse {
  message: string;
  type: 'SUCCESS' | 'ERROR';
}
/**
 *
 */
export enum APISERVICES {
  VA_API,
  ACTIVITY_LOGS_API,
  DEVICE_API,
  EVAL_EXPR_API,
  HOST_API,
  DEVICE_URL,
  HOST_URL,
  ACTIVITY_LOGS_URL,
  CHAT_URL,
  CHAT_API,
  ID_PROVIDER,
  EXPORT_API
}
/**
 *
 */

export enum HTTP_METHOD {
  GET = 'GET',
  POST = 'POST',
  DELETE = 'DELETE',
  PUT = 'PUT',
  PATCH = 'PATCH'
}

export type HttpMethod = keyof typeof HTTP_METHOD;

export const HttpMethodConstants = {
  ...HTTP_METHOD
} as const;
/**
 *
 */
export class AuthService implements AuthType {
  configure = (amplifyConfig?: any) => {
    // Checking if existing Cognito config is available in Amplify
    const existingAuthConfig = Amplify.getConfig().Auth?.Cognito;

    const AuthConfig: ResourcesConfig['Auth'] = existingAuthConfig
      ? { Cognito: { ...existingAuthConfig } }
      : { Cognito: { ...amplifyConfig } };

    const APIConfig: ResourcesConfig['API'] = {
      REST: {
        [`${VITE_HOST_API}`]: {
          endpoint: `${VITE_HOST_URL}`
        },
        [`${VITE_DEVICE_API}`]: {
          endpoint: `${VITE_DEVICE_URL}`
        },
        [`${VITE_DEVICE_URL}`]: {
          endpoint: `${VITE_DEVICE_URL}`
        },
        [`${VITE_HOST_URL}`]: {
          endpoint: `${VITE_HOST_URL}`
        },
        [`${VITE_EVAL_EXPR_API}`]: {
          endpoint: `${VITE_EVAL_EXPR_URL}`
        },
        [`${VITE_CHAT_API}`]: {
          endpoint: `${VITE_CHAT_URL}`
        },
        [`${VITE_ACTIVITY_LOGS_API}`]: {
          endpoint: `${VITE_ACTIVITY_LOGS_URL}`
        },
        [`${VITE_VA_API}`]: {
          endpoint: `${VITE_VA_URL}`
        },
        [`${VITE_EXPORT_URL}`]: {
          endpoint: `${VITE_EXPORT_URL}`
        }
      }
    };

    const libraryOptions: LibraryOptions = {
      API: {
        REST: {
          headers: async () => {
            const authToken = (await this.getCurrentSession()).tokens?.idToken?.toString();
            return {
              Authorization: `Bearer ${authToken}`
            };
          }
        }
      }
    };

    return Amplify.configure(
      {
        Auth: AuthConfig,
        API: APIConfig
      },
      libraryOptions
    );
  };

  isConfigured = () => {
    const config = Amplify.getConfig();
    return config && (config.Auth || config.API) !== undefined;
  };

  //TODO: DO NOT USE THIS METHOD ANYMORE
  /**
   *
   * @param apiEnd
   * @deprecated We are going to remove this method (getAPIName) in next version, kindly use getAPIWithServiceName method instead
   * @returns
   */
  getAPIName = (apiEnd: string) => {
    if (apiEnd.toLowerCase().includes('device_narrowbands')) {
      return `${VITE_DEVICE_API}`;
    } else if (
      VITE_VA_API &&
      (apiEnd.toLowerCase().includes('narrowbands') ||
        apiEnd.toLowerCase().includes('narrowbands_list') ||
        apiEnd.toLowerCase().includes('event_data') ||
        apiEnd.toLowerCase().includes('time_domain_analysis') ||
        apiEnd.toLowerCase().includes('narrowband_data'))
    ) {
      return `${VITE_VA_API}`;
    } else if (apiEnd.toLowerCase().includes('activity_logs')) {
      return `${VITE_ACTIVITY_LOGS_API}`;
    } else if (
      apiEnd.toLowerCase().includes('device') ||
      apiEnd.toLowerCase().includes('device_dashboard') ||
      apiEnd.toLowerCase().includes('generic_dashboard') ||
      apiEnd.toLowerCase().includes('firmware') ||
      apiEnd.toLowerCase().includes('alerts') ||
      apiEnd.toLowerCase().includes('derived_expressions') ||
      apiEnd.toLowerCase().includes('component_compiler') ||
      apiEnd.toLowerCase().includes('anomalies')
    ) {
      return `${VITE_DEVICE_API}`;
    } else if (apiEnd.toLowerCase().includes('powertrain')) {
      return `${VITE_DEVICE_API}`;
    } else if (apiEnd.toLowerCase().includes('eval_expr')) {
      return `${VITE_EVAL_EXPR_API}`;
    } else {
      return `${VITE_HOST_API}`;
    }
  };

  /**
   *
   * @param apiEnd
   * @deprecated We are going to remove this method (getAPIName) in next version, kindly use getAPIWithServiceName method instead
   * @returns
   */
  getAPIUrl = (apiEnd: string) => {
    if (apiEnd.toLowerCase().includes('activity_logs')) {
      return `${import.meta.env.VITE_ACTIVITY_LOGS_URL}`;
    } else if (apiEnd.toLowerCase().includes('device')) {
      return `${import.meta.env.VITE_DEVICE_URL}`;
    } else if (apiEnd.toLowerCase().includes('firmware')) {
      return `${import.meta.env.VITE_DEVICE_URL}`;
    } else {
      return `${import.meta.env.VITE_HOST_URL}`;
    }
  };

  getAPIWithServiceName = (apiEnd: string, service?: APISERVICES) => {
    switch (service) {
      case APISERVICES.VA_API: {
        return `${VITE_VA_API}`;
      }
      case APISERVICES.ACTIVITY_LOGS_API: {
        return `${VITE_ACTIVITY_LOGS_API}`;
      }
      case APISERVICES.CHAT_API: {
        return `${VITE_CHAT_API}`;
      }
      case APISERVICES.DEVICE_API: {
        return `${VITE_DEVICE_API}`;
      }
      case APISERVICES.EVAL_EXPR_API: {
        return `${VITE_EVAL_EXPR_API}`;
      }
      case APISERVICES.HOST_API: {
        return `${VITE_HOST_API}`;
      }
      case APISERVICES.DEVICE_URL: {
        return `${VITE_DEVICE_URL}`;
      }
      case APISERVICES.HOST_URL: {
        return `${VITE_HOST_URL}`;
      }
      case APISERVICES.ACTIVITY_LOGS_URL: {
        return `${VITE_ACTIVITY_LOGS_URL}`;
      }
      case APISERVICES.CHAT_URL: {
        return `${VITE_CHAT_URL}`;
      }
      case APISERVICES.ID_PROVIDER: {
        return `${VITE_HOST_API}`;
      }
      case APISERVICES.EXPORT_API: {
        return `${VITE_EXPORT_URL}`;
      }
      default: {
        return this.getAPIName(apiEnd);
      }
    }
  };

  callAmplifyGetAPI = async (apiEnd: string, params?: any, service?: APISERVICES): Promise<any> => {
    const apiServiceName = this.getAPIWithServiceName(apiEnd, service);
    const response = get({
      apiName: apiServiceName,
      path: apiEnd,
      options: {
        queryParams: params
      }
    });

    const { body } = await response.response;
    const str: string = await body.text();
    return JSON.parse(str);
  };

  callAmplifyPostAPI = async (
    apiEnd: string,
    body?: any,
    params?: any,
    service?: APISERVICES
  ): Promise<any> => {
    const apiServiceName = this.getAPIWithServiceName(apiEnd, service);
    const response = post({
      apiName: apiServiceName,
      path: apiEnd,
      options: {
        queryParams: params,
        body
      }
    });
    const { body: responseBody } = await response.response;
    const str: string = await responseBody.text();
    return JSON.parse(str);
  };

  axiosFileUploadPut = async (apiEnd: string, object?: any, params?: any, headers?: any) => {
    return fetch(apiEnd, {
      method: 'PUT',
      body: object,
      headers: {
        'Content-Type': 'image/jpg',
        'Content-Disposition': 'attachment',
        ...headers
      }
    });
  };

  axiosFileUploadPost = async (
    apiEnd: string,
    formData?: any,
    params?: any,
    headers?: any,
    service?: APISERVICES
  ) => {
    // awsconfig here is the configuration you setup to use amplify already with your app
    const url = this.getAPIWithServiceName(apiEnd, service) + apiEnd; // this.getAPIUrl(apiEnd)
    const authToken = (await this.getCurrentSession()).tokens?.idToken?.toString();
    return await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${authToken}`
      }
    });
  };

  callAmplifyPutAPI = async (apiEnd: string, body?: any, service?: APISERVICES): Promise<any> => {
    const input = {
      body: body
    };
    const apiServiceName = this.getAPIWithServiceName(apiEnd, service);
    const response = put({ apiName: apiServiceName, path: apiEnd, options: input });
    const { statusCode, body: responseBody } = await response.response;
    // No Content
    if (statusCode === 204) {
      return null;
    }
    const str: string = await responseBody.text();
    return JSON.parse(str);
  };

  callAmplifyPatchAPI = async (apiEnd: string, body?: any, service?: APISERVICES): Promise<any> => {
    const input = {
      body: body
    };
    const apiServiceName = this.getAPIWithServiceName(apiEnd, service);
    const response = patch({ apiName: apiServiceName, path: apiEnd, options: input });
    const { body: responseBody } = await response.response;
    const str: string = await responseBody.text();
    return JSON.parse(str);
  };

  callAmplifyDeleteAPI = (apiEnd: string, params?: any, service?: APISERVICES): Promise<any> => {
    this.configure();
    const apiServiceName = this.getAPIWithServiceName(apiEnd, service);
    const response = del({
      apiName: apiServiceName,
      path: apiEnd,
      options: {
        queryParams: params
      }
    });
    return response.response;
  };

  signUp = (user: SignUpUser): Promise<SignUpOutput> => {
    return signUp({
      username: user.email,
      password: user.password,
      options: {
        userAttributes: {
          email: user.email,
          given_name: user.givenName,
          family_name: user.familyName,
          /* 'custom:confirmPassword': user.retypePassword, */
          'custom:country_code': user.country_code,
          'custom:org_name': user.org_name,
          'custom:slug': user.slug
        },
        autoSignIn: true
      }
    });
  };
  /**
   *
   */
  signIn = (user: SignInUser): Promise<SignInOutput> => {
    return signIn({
      username: user.email,
      password: user.password
    });
  };
  /**
   *
   */
  // aws amplify uses the credentials and context of the current user session under the hood
  updatePassword = (data: any): Promise<ConfirmSignInOutput> => {
    return confirmSignIn({ challengeResponse: data.newPassword });
  };

  // aws amplify uses the credentials and context of the current user session under the hood
  changePassword = (data: any): Promise<void> => {
    return updatePassword({ oldPassword: data.oldPassword, newPassword: data.newPassword });
  };
  /**
   *
   */
  confirmSignIn = () => {
    // return Auth.confirmSignIn();
  };

  /**
   *
   * @returns
   */

  decodePayload = (jwtToken: any) => {
    const payload = jwtToken.split('.')[1];
    try {
      return JSON.parse(atob(payload));
    } catch (err) {
      return {};
    }
  };

  calculateClockDrift = (iatAccessToken: number, iatIdToken: number) => {
    const now = Math.floor(new Date().getTime() / 1000);
    const iat = Math.min(iatAccessToken, iatIdToken);
    return now - iat;
  };

  navigateToFederatedIdentityProvider = (providerName: string, redirection?: string) => {
    const navigationPath = `${
      import.meta.env.VITE_AUTH_USER_POOL_DOMAIN
    }/oauth2/authorize?identity_provider=${providerName}&redirect_uri=${
      import.meta.env.VITE_BASE_PATH
    }org/auth/${redirection || 'federated_auth'}/&response_type=CODE&client_id=${
      import.meta.env.VITE_AUTH_USER_POOL_WEB_CLIENT
    }&client_secret=${
      import.meta.env.VITE_AUTH_USER_POOL_WEB_CLIENT_SECRET
    }&scope=aws.cognito.signin.user.admin%20email%20openid%20profile`;
    window.location.replace(navigationPath);
  };

  navigateToFederatedLogOut = (redirect_uri?: string) => {
    const navigationPath = `${import.meta.env.VITE_AUTH_USER_POOL_DOMAIN}/logout?&client_id=${import.meta.env.VITE_AUTH_USER_POOL_WEB_CLIENT}&logout_uri=${import.meta.env.VITE_BASE_PATH}`;
    window.location.replace(navigationPath);
    // This condition handles the scenario where a logged-in user attempts to accept an invite meant for a different user.
    // In this case, the app prompts the user to either log out or reject the invite.
    // If the user chooses to log out and proceed with the invite acceptance, they are redirected to the `BASE_PATH` URL,
    // which is already registered in the Cognito user pool.
    // After this redirection, we need to replace the URL with the `accept-invite` URL to ensure the correct flow.
    // The code below manages this redirection to `redirect_uri` accordingly.

    if (redirect_uri) {
      window.location.replace(redirect_uri);
    }
  };

  handleFederatedSession = (code: string, callback: Function, redirection?: string) => {
    const details: {
      [key: string]: string | undefined;
    } = {
      grant_type: 'authorization_code',
      code,
      client_id: VITE_AUTH_USER_POOL_WEB_CLIENT,
      client_secret: VITE_AUTH_USER_POOL_WEB_CLIENT_SECRET,
      redirect_uri: `${VITE_BASE_PATH}org/auth/${redirection || 'federated_auth'}/`
    };
    const formBody = Object.keys(details)
      .map((key: string) => `${encodeURIComponent(key)}=${encodeURIComponent(details[key] || '')}`)
      .join('&');

    fetch(`${VITE_AUTH_USER_POOL_DOMAIN}/oauth2/token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      body: formBody
    }).then((res) => {
      res.json().then((data) => {
        const idTokenData = this.decodePayload(data.id_token);
        const accessTokenData = this.decodePayload(data.access_token);

        localStorage.setItem(
          'CognitoIdentityServiceProvider.' + VITE_AUTH_USER_POOL_WEB_CLIENT + '.LastAuthUser',
          idTokenData['cognito:username']
        );
        localStorage.setItem(
          `${'CognitoIdentityServiceProvider.' + VITE_AUTH_USER_POOL_WEB_CLIENT + '.'}${
            idTokenData['cognito:username']
          }.idToken`,
          data.id_token
        );
        localStorage.setItem(
          `${'CognitoIdentityServiceProvider.' + VITE_AUTH_USER_POOL_WEB_CLIENT + '.'}${
            idTokenData['cognito:username']
          }.accessToken`,
          data.access_token
        );
        localStorage.setItem(
          `${'CognitoIdentityServiceProvider.' + VITE_AUTH_USER_POOL_WEB_CLIENT + '.'}${
            idTokenData['cognito:username']
          }.refreshToken`,
          data.refresh_token
        );
        localStorage.setItem(
          `${'CognitoIdentityServiceProvider.' + VITE_AUTH_USER_POOL_WEB_CLIENT + '.'}${
            idTokenData['cognito:username']
          }.clockDrift`,
          `${this.calculateClockDrift(accessTokenData.iat, idTokenData.iat)}`
        );
        callback({
          email: idTokenData['cognito:username'],
          username: idTokenData['cognito:username'],
          accessToken: data.access_token,
          idToken: data.id_token,
          refreshToken: data.refresh_token
        });
      });
    });
  };
  /**
   *
   */
  signOutCurrentUser = () => {
    return signOut();
  };
  /**
   *
   */
  getCurrentUser = async () => {
    return await getCurrentUser();
  };
  /**
   *
   */
  getCurrentSession = async () => {
    return await fetchAuthSession();
  };
  /**
   *
   */
  forgotPassword = async (email: string): Promise<any> => {
    return resetPassword({ username: email })
      .then(() => {
        return {
          message: 'Password Reset link sent to email',
          type: 'SUCCESS'
        };
      })
      .catch((error: any) => {
        return { message: '' + error, type: 'ERROR' };
      });
  };
  /**
   *
   */

  forgotPasswordSubmit = async (data: any): Promise<void> => {
    return await confirmResetPassword({
      username: data.email,
      confirmationCode: data.code,
      newPassword: data.password
    });
  };

  /* emailAlreadyExists = (email: string) => {
    const code = '000000';
    return Auth.confirmSignUp(email, code, {
      // If set to False, the API will throw an AliasExistsException error if email already exists
      // as an alias with a different user
      forceAliasCreation: false,
    }).catch((err: any) => {
      switch (err.code) {
        case 'UserNotFoundException':
          return true;
        case 'NotAuthorizedException':
          return false;
        case 'AliasExistsException':
          //This exception occurs if email is already registered
          return true;
        case 'CodeMismatchException':
          return false;
        case 'ExpiredCodeException':
          return false;
        default:
          return false;
      }
    });
  }; */

  sendEmailLink = async (username: string): Promise<AuthResponse> => {
    return await resendSignUpCode({ username })
      .then((): AuthResponse => {
        return {
          type: 'SUCCESS',
          message: 'Verification link sent to email'
        };
      })
      .catch((e: any): AuthResponse => {
        return {
          type: 'ERROR',
          message: e || 'Failed to send verification link'
        };
      });
  };

  connectToMQTTBroker = () => {
    const pubsub = new PubSub({
      aws_pubsub_region: VITE_AUTH_REGION,
      aws_pubsub_endpoint: `wss://${VITE_MQTT_ID}.iot.${VITE_AUTH_REGION}.amazonaws.com/mqtt`,
      clientId: this.uuidv4()
    });
    return pubsub;
  };

  getDataFromTopic = (topics: Array<string>) => {
    return this.connectToMQTTBroker().subscribe({ topics });
  };

  uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0,
        v = c == 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };
}
