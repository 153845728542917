/**
 * SLBattery status viewer
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import SLTable from '../../table/SLTable';
import { ChartViewProps } from '../common/ChartViewProps';
import { SLButton } from '../../button';
import { APISERVICES, httpPatch } from '../../../services';
import { useSlugContext } from '../../../lib';
import { useDispatch } from 'react-redux';
import { removeProgressFor, showProgressFor } from '../../progress-bar';
import { ACCESS_PERMIT, usePermissions } from '../../../rbac';
import { DEVICE_PERMISSIONS } from '../../../../features/device/device.permissions';
import SLConfirmationDialog, { CONFIRM_ACTION } from '../../confirm-dialog/SLConfirmationDialog';
import { showToast } from '../../toast';
import { LightTooltip } from '../../tooltip/Tooltip';
import BatteryStatusFormattedCell from './BatteryStatusFormattedCell';
import TranslateText from '../../../../i18n/TranslateText';

const SLBatteryStatusViewer = React.forwardRef(
  ({ resultSet, chartSettings }: ChartViewProps, ref: any) => {
    const [tableData, setTableData] = useState(resultSet);
    const [tableHeight, setTableHeight] = useState(400);
    const [displayConfirmationDialog, setDisplayConfirmationDialog] = useState(false);
    const [replaceBatteryDevice, setReplaceBatteryDevice] = useState<any>();
    const stageCanvasRef: any = useRef(null);
    const { slug } = useSlugContext();
    const dispatch = useDispatch();
    function onConfirmBatteryReplacement(event: CONFIRM_ACTION) {
      setDisplayConfirmationDialog(false);
      if (event == CONFIRM_ACTION.CANCEL) {
        return;
      }
      dispatch(showProgressFor(`UPDATE_BATTERY_STATUS_${replaceBatteryDevice?.device_id}`));
      try {
        httpPatch(
          `orgs/${slug}/devices/${replaceBatteryDevice.device_id}/derived_datapoints/${replaceBatteryDevice.bat_accu_dpid}`,
          { reset_value: 0 },
          APISERVICES.DEVICE_API
        )
          .then(() => {
            dispatch(removeProgressFor(`UPDATE_BATTERY_STATUS_${replaceBatteryDevice.device_id}`));
            setTableData(
              resultSet.filter((row: any) => row.device_id !== replaceBatteryDevice.device_id)
            );
            dispatch(showToast('Battery status updated successfully', 'success'));
          })
          .catch((errorObject: any) => {
            dispatch(showToast('Failed to update battery status', 'error'));
          })
          .finally(() => {
            dispatch(removeProgressFor(`UPDATE_BATTERY_STATUS_${replaceBatteryDevice.device_id}`));
          });
      } catch (errorObject) {
        // Show error message
        dispatch(showToast('Failed to update battery status', 'error'));
      }
    }
    useImperativeHandle(
      ref,
      () => ({
        resize() {
          if (stageCanvasRef?.current) {
            const height = stageCanvasRef?.current?.offsetParent?.clientHeight;
            setTableHeight(height);
          } else {
            setTableHeight(500);
          }
        }
      }),
      []
    );

    useEffect(() => {
      if (stageCanvasRef?.current?.offsetParent?.clientHeight) {
        const height = stageCanvasRef?.current?.offsetParent?.clientHeight;
        if (height >= stageCanvasRef?.current?.scrollHeight) {
          setTableHeight(height);
        }
      } else {
        setTableHeight(500);
      }
    }, [stageCanvasRef?.current]);
    const handleButtonClick = (deviceObject: number) => {
      setReplaceBatteryDevice(deviceObject);
      setDisplayConfirmationDialog(true);
    };

    const handleCancel = () => {
      setDisplayConfirmationDialog(false);
    };

    const UPDATE_DEVICE: ACCESS_PERMIT = usePermissions(DEVICE_PERMISSIONS.UPDATE);
    return (
      <>
        <div ref={stageCanvasRef}>
          <SLTable
            feature="battery-status"
            data={tableData}
            enableTopToolbar
            enableExportData
            csvOptions={{ filename: 'Battery Status Report' }}
            columns={[
              {
                accessorKey: 'device_name',
                header: TranslateText('sensor_name', 'Sensor Name'),
                CellRenderer: ({ cellValue }: any) => (
                  <BatteryStatusFormattedCell
                    value={cellValue}
                    settings={chartSettings['sensor_name']}
                    type="string"
                  />
                ),
                enableSorting: false,
                size: 50,
                minSize: 50
              },
              {
                accessorKey: 'batt_remaining',
                header: TranslateText('total_battery_remaining', 'Total Battery Remaining %'), // 'Total Battery Remaining %',
                CellRenderer: ({ cellValue }: any) => (
                  <BatteryStatusFormattedCell
                    value={cellValue}
                    settings={chartSettings['battery_remaining']}
                    suffix="%"
                    type="number"
                  />
                ),
                enableSorting: false,
                size: 50,
                minSize: 50
              },
              {
                accessorKey: 'days_left',
                header: TranslateText('replace_battery_days', 'Replace Battery (days)'),
                CellRenderer: ({ cellValue }: any) => (
                  <BatteryStatusFormattedCell
                    value={cellValue}
                    settings={chartSettings['replace_battery']}
                    suffix="days"
                    type="number"
                  />
                ),
                enableSorting: false,
                size: 50,
                minSize: 50
              },
              {
                accessorKey: 'device_id',
                header: TranslateText('action', 'Action'),
                CellRenderer: ({ rowObj }: any) => (
                  <LightTooltip
                    title="Log Battery Replacement"
                    translationId="log_battery_replacement">
                    <SLButton
                      onClick={() => {
                        handleButtonClick(rowObj);
                      }}
                      variant={UPDATE_DEVICE !== ACCESS_PERMIT.ALLOW ? 'outlined' : 'contained'}
                      disabled={UPDATE_DEVICE !== ACCESS_PERMIT.ALLOW}
                      style={{ fontSize: 12 }}
                      translationId="battery_replaced">
                      Battery Replaced
                    </SLButton>
                  </LightTooltip>
                ),
                enableSorting: false,
                size: 50,
                minSize: 50
              }
            ]}
            enableColumnResizing
            maxBodyHeight={tableHeight - 175}
            fixedLayout
            enableSorting
            enableBottomToolbar
            enablePagination
          />
        </div>
        <SLConfirmationDialog
          message="Please confirm if battery is replaced on Sensor?"
          translationId="battery_replacement_msg"
          onConfirm={onConfirmBatteryReplacement}
          confirmButtonName="Yes"
          displayConfirmationDialog={displayConfirmationDialog}
          onClose={handleCancel}
        />
      </>
    );
  }
);

export default SLBatteryStatusViewer;
