/**
 * SLTextfield component
 * @author aditya.bhadange@shorelineiot.com
 */
import React, { ReactElement, ReactNode, useEffect } from 'react';
import { FormControl, InputLabelProps, InputProps, TextField } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';
import { useStyles } from './common/SLForms.styles';
import { SLInputLabelProps } from './SLInputLabel';
import { validationMode } from './common/ValidationMode.constants';
import TranslateText from '../../../i18n/TranslateText';

export type SLTextFieldProps = {
  name: string;
  label: ReactNode;
  labelProps?: SLInputLabelProps;
  inputProps?: InputProps;
  rules?: Object | Function;
  watchField?: string;
  value?: any;
  defaultValue?: any;
  size?: 'small' | 'medium';
  variant?: 'standard' | 'outlined' | undefined;
  autoFocus?: boolean;
  updateValue?: (value: string) => string;
  onBlur?: ((value: any, setFn: any, getFn: any, changedValue?: any) => void) | undefined;
  onChange?: ((value: any, setFn: any, getFn: any) => void) | undefined;
  onFocus?: ((value: any, setFn: any, getFn: any) => void) | undefined;
  onKeyDown?: ((value: any) => any) | undefined;
  /**
   * Trigger validation on the input field event depending
   * on the ```triggerValidationMode``` selection.
   * Default is ```'onBlur'```
   */
  triggerValidationMode?: 'onBlur' | 'onChange' | 'onFocus' | 'all' | undefined;
  disabled?: boolean;
  [key: string]: any;
  type?: React.InputHTMLAttributes<unknown>['type'];
  /**
   * Allow negative values
   */
  allowNegative?: boolean;
  /**
   * allow decimal values
   * @default true
   */
  allowDecimal?: boolean;
  /**
   * Character limit for the input field
   */
  characterLimit?: number;
  testID?: string;
  placeholder?: string;
  labelTranslationId?: string;
  placeholderTranslationId?: string;
};

export default function SLTextField({
  name,
  label,
  rules,
  onBlur,
  defaultValue,
  onChange,
  onFocus,
  onKeyDown,
  value,
  size = 'small',
  variant = 'standard',
  labelProps,
  inputProps,
  triggerValidationMode = undefined,
  disabled = false,
  autoFocus = false,
  type = 'text',
  allowNegative = true,
  allowDecimal = true,
  characterLimit,
  testID,
  labelTranslationId,
  placeholder,
  placeholderTranslationId,
  ...otherProps
}: SLTextFieldProps): ReactElement {
  const {
    watch,
    setValue,
    control,
    formState: { errors, defaultValues },
    trigger,
    getValues
  } = useFormContext();

  const classes = useStyles();

  const newLabelProps: InputLabelProps = {
    style: {
      fontWeight: 500,
      color: 'black',
      ...(labelProps && { ...labelProps })
    }
  };

  const newInputProps: InputProps = {
    ...(disabled && { disableunderline: 'true' }),
    ...(inputProps && { ...inputProps })
  };

  /**
   * Trigger validation based on ```triggerValidationMode``` prop value.
   * @param eventType onBlur | onChange | onFocus
   */
  const triggerValidationsOnEvent = async (eventType: string) => {
    if (eventType === triggerValidationMode) {
      await trigger(name, { shouldFocus: false });
    }
  };

  const handleOnBlur = (e: any) => {
    triggerValidationsOnEvent(validationMode.onBlur);
    if (onBlur) {
      onBlur(getValues(), setValue, getValues, e?.target.value);
    }
  };
  const handleOnChange = (event: any) => {
    const inputValue = event?.target?.value;

    // Restrict input to characterLimit if defined
    if (characterLimit && inputValue?.length > characterLimit) {
      event.target.value = inputValue.slice(0, characterLimit);
    }

    triggerValidationsOnEvent(validationMode.onChange);
    if (onChange) {
      onChange(getValues(), setValue(name, event?.target?.value), getValues);
    }
  };
  const handleOnFocus = () => {
    triggerValidationsOnEvent(validationMode.onFocus);
    if (onFocus) {
      onFocus(getValues(), setValue, getValues);
    }
  };
  const onKeyDownEnter = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> &
      React.KeyboardEvent<HTMLDivElement>
  ) => {
    //Custom validation added to avoid accept -(minus) when allowNegative is set to false
    if (!allowNegative && event?.key === '-') {
      event.preventDefault();
    }
    if (!allowDecimal && event?.key === '.') {
      event.preventDefault();
    }
    if (event?.key === 'Enter') {
      triggerValidationsOnEvent(validationMode.onKeyDownEnter);
      if (onKeyDown) {
        onKeyDown(event?.target?.value);
      }
    }
  };

  useEffect(() => {
    if (value) {
      setValue(name, value);
    }
  }, [value]);

  return (
    <FormControl error={Boolean(errors[name])} className={classes.root}>
      <Controller
        name={name}
        control={control}
        rules={typeof rules === 'function' ? rules(watch) : rules}
        defaultValue={defaultValue || defaultValues?.[name] || ''}
        render={({ field: { onChange, onBlur, ref, value }, fieldState: { error } }) => (
          <TextField
            name={name}
            inputRef={ref}
            helperText={error ? error?.message : null}
            size={size}
            error={!!error}
            value={value}
            fullWidth
            label={labelTranslationId ? TranslateText(labelTranslationId) : label}
            placeholder={
              placeholderTranslationId ? TranslateText(placeholderTranslationId) : placeholder
            }
            //Check if the current browser is not Chrome and the input type is 'number' then assign type as text to get char in target value.
            type={
              !window.navigator.userAgent.includes('Chrome') && type === 'number' ? 'text' : type
            }
            variant={variant}
            disabled={disabled}
            InputProps={{
              id: testID,
              ...newInputProps
            }}
            InputLabelProps={newLabelProps}
            autoFocus={autoFocus}
            onChange={(e: any) => {
              const inputValue = e?.target?.value;
              /**
               * Custom validation added for Firefox numeric input:
               * Enforces acceptance of only valid numeric values with a decimal point,
               * and disallowing alphabets and multiple decimal points.
               */
              // Check if the current browser is not chrome and the input type is 'number'
              if (
                !window.navigator.userAgent.includes('Chrome') &&
                type === 'number' &&
                inputValue?.length
              ) {
                // Define a helper function to check
                // if a given string is a valid numeric value
                const isNumeric = (value: string) => {
                  const validNumberPattern = /^(-)?[0-9]+(\.[0-9]*)?([eE][-+]?[0-9]+)?$/;
                  return validNumberPattern.test(value);
                };
                if (typeof inputValue === 'string' && !isNumeric(inputValue)) {
                  return;
                }
              }
              /**
               * Custom validation added for numeric input to avoid negative values when allowNegative is set to false
               */
              if (!allowNegative && type === 'number' && inputValue && Number(inputValue) < 0) {
                return; // Don't allow negative values
              }
              if (!allowDecimal && type === 'number' && inputValue && inputValue === '.') {
                return; // Don't allow decimal values
              }

              // Apply character limit if provided
              if (!characterLimit || inputValue.length <= characterLimit) {
                onChange(e); // Update the value only if within the limit
              }

              handleOnChange(e);
            }}
            onKeyDown={(e: any) => {
              onKeyDownEnter(e);
            }}
            onBlur={(e) => {
              onBlur();
              handleOnBlur(e);
            }}
            onFocus={() => {
              handleOnFocus();
            }}
            {...otherProps}
          />
        )}
      />
    </FormControl>
  );
}
