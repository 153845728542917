import { TooltipFormatterContextObject } from 'highcharts';
import moment from 'moment';
import { setDatapointValueResolution } from '../../dashboard-widgets/SLLineChart/utils/setDatapointValueResolution';

export function rawDataTooltipFormatter(this: TooltipFormatterContextObject): string {
  let formattedString = '<table>';
  const elem = this.point;
  const seriesName = elem?.series?.name;
  const unit = this.series?.options?.custom?.datapoint?.unit;
  const sensorType = this.series?.options?.custom?.datapoint?.sensor_type;
  const currentTime = new Date(elem.x / 1000);

  let yAxisDatapoint: string | number = 0;
  if (elem.y) {
    yAxisDatapoint = setDatapointValueResolution(sensorType, elem.y, unit);
  }

  formattedString += `<tr><td style="color: {series.color}">Device: </td>`;
  formattedString += `<td style="text-align: right"><b>${seriesName}</b></td></tr>`;
  formattedString += `<tr><td>Value: </td>`;
  formattedString += `<td style="text-align: right"><b>${yAxisDatapoint} ${unit}</b></td></tr>`;
  formattedString += `<tr><td>ts:</td><td style="text-align: right"><b>${moment(currentTime).format(
    `MMM DD, YYYY hh:mm:ss A`
  )}</b></td></tr></table>`;
  return formattedString;
}
