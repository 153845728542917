/**
 * Tooltip
 * @author mahesh.kedari@shorelineiot.com
 */
import { TooltipClasses } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
/**
 *
 */
export const TooltipStyle: Partial<OverridesStyleRules<keyof TooltipClasses, 'MuiTooltip'>> = {
  tooltip: {
    fontWeight: 300,
    maxHeight: '300px',
    overflowY: 'scroll',
    scrollbarWidth: 'none'
  }
};
